import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CustomerAccessTokenCreateInput,
  useCustomerAccessTokenCreateMutation,
  useCustomerActivateByUrlMutation,
  useCustomerCreateMutation,
  useCustomerRecoverMutation,
  useGetCustomerLazyQuery,
} from "../../../generated/storefront";
import { validate } from "../../../helpers/formValidation";
import { createWishlist } from "../../../services/Firebase/wishlists";
import AuthContext from "../../../state/auth";
import { getCustomerByEmail } from "../../../services/API/customer";
import { identifyCustomer } from "../../../services/API/events";

type CustomerData = {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
};

const useLoginCustomer = () => {
  const navigate = useNavigate();
  const [URLSearchParams] = useSearchParams();
  const { setLoadingCustomer } = useContext(AuthContext);
  const redirect_url = URLSearchParams.get("redirect_url");
  const [signUpUserData, setSignUpUserData] = useState<CustomerData>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [signInUserData, setSignInUserData] = useState<CustomerData>({ email: "", password: "" });
  const [signInErrors, setSignInErrors] = useState<CustomerData>({});
  const [signUpErrors, setSignUpErrors] = useState<CustomerData>({});
  const [acceptsMarketing, setAcceptsMarketing] = useState(true);
  const [accountActivationUrl, setAccountActivationUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [newAccount, setNewAccount] = useState(false);
  const [customerLogin] = useCustomerAccessTokenCreateMutation();
  const [customerCreate] = useCustomerCreateMutation();
  const [getCustomer] = useGetCustomerLazyQuery();
  const [activateCustomer] = useCustomerActivateByUrlMutation();
  const [recoverCustomer] = useCustomerRecoverMutation();

  const onCustomerLoginChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setSignInErrors({ ...signInErrors, [name]: "" });
    setSignInUserData({ ...signInUserData, [name]: value });
  };

  const onCustomerRegisterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setSignUpErrors({ ...signUpErrors, [name]: "" });
    setSignUpUserData({ ...signUpUserData, [name]: value });
  };

  const onCustomerCheck = async () => {
    setLoading(true);
    if (!signInUserData.email) return setSignInErrors({ email: "Email is required" });
    try {
      const data = await getCustomerByEmail({ email: signInUserData.email });
      if (data.accountActivationUrl) {
        setSignUpUserData({
          email: data.customer.email || undefined,
          firstName: data.customer.firstName || undefined,
          lastName: data.customer.lastName || undefined,
        });
        setAccountActivationUrl(data.accountActivationUrl);
      } else {
        const customer = data.customer;
        setSignInUserData({ ...signInUserData, firstName: customer.firstName || "" });
      }
      setLoading(false);
    } catch (error: any) {
      if (error.response?.status === 404) {
        setSignUpUserData({ email: signInUserData.email });
        return setLoading(false);
      }
      setLoading(false);
      setSignInErrors({ email: "Something went wrong, please get in touch if issue persists." });
      throw error;
    }
  };

  const handleLoginCustomer = async (accessToken: string, redirect = true) => {
    localStorage.setItem("customerAccessToken", accessToken || "");
    const { data: customerData } = await getCustomer({
      variables: {
        customerAccessToken: accessToken || "",
      },
    });
    const wishlistIds = JSON.parse(localStorage.getItem("wishlist") || "[]");
    if (wishlistIds.length > 0) {
      if (customerData?.customer) {
        await createWishlist({
          name: "My wishlist",
          productIds: wishlistIds,
          ownerId: customerData.customer.id,
          privacyType: "private",
          collaborators: [],
        });
        localStorage.removeItem("wishlist");
      }
    }
    if (customerData?.customer?.email)
      identifyCustomer({
        email: customerData.customer.email,
        firstName: customerData.customer.firstName,
        lastName: customerData.customer.lastName,
      });
    setLoadingCustomer(true);
    if (redirect) {
      navigate(redirect_url || "/");
    }
    setLoading(false);
  };

  const loginCustomer = async (event: FormEvent<HTMLFormElement>, redirect = true) => {
    event.preventDefault();
    setLoading(true);
    const { email, password } = signInUserData;
    const validation = validate({ email, password });
    if (validation.error) {
      setSignInErrors(validation.errors);
      setLoading(false);
      return;
    }

    const variables: { input: CustomerAccessTokenCreateInput } = {
      input: {
        email: validation.state.email,
        password: validation.state.password,
      },
    };

    try {
      const { data } = await customerLogin({ variables });
      const { customerAccessToken, customerUserErrors } = data?.customerAccessTokenCreate || {};

      if (!customerAccessToken || (customerUserErrors && customerUserErrors.length > 0)) {
        console.error("Errors:", customerUserErrors);
        setSignInErrors({ email: "Invalid email or password" });
        setLoading(false);
        return null;
      }

      await handleLoginCustomer(customerAccessToken.accessToken, redirect);
    } catch (error) {
      console.error("Error while trying to login customer", error);
      setLoading(false);
      return null;
    }
  };

  const handleSignUpErrors = (errors: any) => {
    const emailErrors = errors.filter((error: any) => error.field?.includes("email"));
    const passwordErrors = errors.filter((error: any) => error.field?.includes("password"));

    setSignUpErrors({
      email: emailErrors.length > 0 ? emailErrors[0].message : "",
      password: passwordErrors.length > 0 ? passwordErrors[0].message : "",
    });
    console.error("Errors:", errors);
  };

  const registerCustomer = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validation = validate(signUpUserData);
    if (validation.error) {
      setSignUpErrors({ ...signUpErrors, ...validation.errors });
      setLoading(false);
      return;
    }
    const { email, firstName, lastName, password } = validation.state;

    const variables = {
      input: {
        email,
        password,
        firstName,
        lastName,
        acceptsMarketing,
      },
    };

    try {
      if (accountActivationUrl) {
        const { data } = await activateCustomer({
          variables: {
            activationUrl: accountActivationUrl,
            password: validation.state.password,
          },
        });
        const { customerAccessToken, customerUserErrors } = data?.customerActivateByUrl || {};

        if (customerUserErrors && customerUserErrors.length > 0) {
          handleSignUpErrors(customerUserErrors);
          return null;
        }

        if (!customerAccessToken) {
          setSignUpErrors({ email: "Invalid email or password" });
          setLoading(false);
          return null;
        }

        await handleLoginCustomer(customerAccessToken.accessToken);
      } else {
        const { data } = await customerCreate({ variables });
        const { customer, customerUserErrors } = data?.customerCreate || {};

        if (customerUserErrors && customerUserErrors.length > 0) {
          handleSignUpErrors(customerUserErrors);
          return null;
        }

        if (!customer) {
          setSignUpErrors({ email: "Could not create account, please try again." });
          setLoading(false);
          return null;
        }

        setSuccessMessage("Account created successfully, please login");
        setNewAccount(true);
        setSignUpUserData({ email: "", password: "", firstName: "", lastName: "" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error while trying to register customer", error);
      setLoading(false);
      return null;
    }
  };

  const onAcceptsMarketingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAcceptsMarketing(event.target.checked);
  };

  const onForgotPassword = async (email: string) => {
    setLoading(true);
    try {
      await recoverCustomer({ variables: { email } });
      setSuccessMessage("Password recovery email sent");
      setLoading(false);
    } catch (error) {
      console.error("Error while trying to recover password", error);
      setLoading(false);
    }
  };

  return {
    onCustomerCheck,
    onCustomerLoginChange,
    onCustomerRegisterChange,
    loginCustomer,
    registerCustomer,
    loading,
    signInUserData,
    signUpUserData,
    successMessage,
    signInErrors,
    signUpErrors,
    acceptsMarketing,
    onAcceptsMarketingChange,
    onForgotPassword,
    newAccount,
  };
};

export default useLoginCustomer;
