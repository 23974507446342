import { Route, Routes } from "react-router";
import { Box } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import AuthGuard from "./components/AuthGuard";
import EditProduct from "./pages/Profile/EditProduct";
import ProductBreakdown from "./pages/Profile/ProductBreakdown";
import Profile from "./pages/Profile";
import Nav from "./components/Nav";
import GalleryWallBuilder from "./components/GalleryWallBuilder";
// import { Margin } from "./components/Layout";
import { useAppState } from "./state";
import { adminClient, storefrontClient } from "./helpers/apolloClients";
import RollingTextBanner from "./components/RollingTextBanner";
import { colors } from "./theme";

const ArtistApp = () => {
  const { fixedNavHeight, isMobileScreen, galleryWallBuilderIsOpen, toggleGalleryWallBuilderIsOpen } = useAppState();
  return (
    <div>
      <ApolloProvider client={storefrontClient}>
        <Box position="relative" zIndex={1}>
          <RollingTextBanner
            text="Delivery on us 🎄 This week only ⏰ "
            background={colors.christmasRed}
            color={colors.white}
            padding={10}
            size={isMobileScreen ? 10 : 14}
            speed={40}
          />
        </Box>
        <Nav />
        {galleryWallBuilderIsOpen && (
          <GalleryWallBuilder drawerIsOpen={galleryWallBuilderIsOpen} toggleDrawerIsOpen={toggleGalleryWallBuilderIsOpen} />
        )}
      </ApolloProvider>
      {/* <Margin margin={fixedNavHeight} /> */}
      <div style={{ minHeight: `calc(100vh - ${fixedNavHeight}px)` }}>
        <ApolloProvider client={adminClient}>
          <Routes>
            <Route path="/dashboard/:id/edit" element={<AuthGuard element={EditProduct} />} />
            <Route path="/dashboard/:id" element={<AuthGuard element={ProductBreakdown} />} />
            <Route path="/" element={<AuthGuard element={Profile} />} />
          </Routes>
        </ApolloProvider>
      </div>
    </div>
  );
};

export default ArtistApp;
