import axios from "axios";
import { EventType } from "../../types/email";

export const triggerEvent = async ({
  email,
  type,
  properties = {},
}: {
  email: string;
  type: EventType;
  properties?: Record<string, any>;
}) => {
  const options = {
    method: "POST",
    url: "https://a.klaviyo.com/client/events",
    params: { company_id: "R6qAGw" },
    headers: {
      accept: "application/json",
      revision: "2024-07-15",
      "content-type": "application/json",
    },
    data: JSON.stringify({
      data: {
        type: "event",
        attributes: {
          properties,
          metric: { data: { type: "metric", attributes: { name: type } } },
          profile: {
            data: {
              type: "profile",
              attributes: {
                email,
              },
            },
          },
        },
      },
    }),
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const addContact = async ({
  email,
  firstName,
  lastName,
  tags,
}: {
  email: string;
  firstName?: string;
  lastName?: string;
  tags?: string[];
}) => {
  const options = {
    method: "POST",
    url: "https://a.klaviyo.com/client/subscriptions",
    params: { company_id: "R6qAGw" },
    headers: { revision: "2024-10-15", "content-type": "application/vnd.api+json" },
    data: JSON.stringify({
      data: {
        type: "subscription",
        attributes: {
          profile: {
            data: {
              type: "profile",
              attributes: {
                email,
                first_name: firstName,
                last_name: lastName,
                properties: {
                  Tags: (tags || []).join(","),
                },
              },
            },
          },
        },
        relationships: { list: { data: { type: "list", id: "VBnFBw" } } },
      },
    }),
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
