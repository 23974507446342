import { useCallback, useEffect, useState } from "react";
import { compact, isEmpty } from "lodash";
import {
  CollectionSortKeys,
  PrintSetProductFragment,
  ProductCollectionSortKeys,
  useGetComplimentaryProductsLazyQuery,
  useGetPrintSetCollectionsQuery,
  useGetProductsForChosenStyleLazyQuery,
} from "../../generated/storefront";
import { mediumOptions, styleFilterOptions } from "../../state/constants";
import { getIdNumber } from "../../helpers/shopify";

export type PrintSet = {
  link: string;
  product: PrintSetProductFragment;
  complimentaryProduct: PrintSetProductFragment;
};

const usePrintSets = () => {
  const [mediumImagery, setMediumImagery] = useState<{ [key: string]: PrintSetProductFragment[] }>({});
  const [styleImagery, setStyleImagery] = useState<{ [key: string]: PrintSetProductFragment[] }>({});
  const [selectedMediums, setSelectedMediums] = useState<string[]>([]);
  const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
  const [printSets, setPrintSets] = useState<PrintSet[]>([]);
  const [personalisedPrintSets, setPersonalisedPrintSets] = useState<PrintSet[]>();
  const [loadingPersonalised, setLoadingPersonalised] = useState(false);
  const [getProductsForChosenStyle] = useGetProductsForChosenStyleLazyQuery();
  const [getComplimentaryProducts] = useGetComplimentaryProductsLazyQuery();
  const {
    data,
    loading,
    fetchMore: fetchMorePrintSets,
  } = useGetPrintSetCollectionsQuery({
    variables: {
      sortKey: CollectionSortKeys.Title,
      limit: 12,
    },
  });
  const fetchMore = () => {
    fetchMorePrintSets({
      variables: {
        afterCursor: data?.collections?.pageInfo.endCursor,
      },
    });
  };

  const filters = [
    ...selectedMediums.map((m) => ({ productMetafield: { namespace: "custom", key: "medium", value: m } })),
    ...selectedStyles.flatMap((s) => [{ tag: s }, { tag: `gm.${s}` }]),
  ];

  const [getPersonalisedPrintSets, { data: personalisedPrintSetsData, fetchMore: fetchMorePersonalisedPrintSets }] =
    useGetProductsForChosenStyleLazyQuery({
      variables: {
        filters,
        sortKey: ProductCollectionSortKeys.Relevance,
        limit: 12,
      },
    });

  const getMediumImagery = useCallback(async () => {
    const imagery: { [key: string]: PrintSetProductFragment[] } = {};
    const promises = mediumOptions.map(async (medium) => {
      const { data } = await getProductsForChosenStyle({
        variables: {
          filters: [{ productMetafield: { namespace: "custom", key: "medium", value: medium.value } }],
          sortKey: ProductCollectionSortKeys.BestSelling,
          limit: 6,
        },
      });
      const products = data?.collection?.products.nodes || [];
      const uniqueArtistProducts = products.filter(
        (product, index, self) => index === self.findIndex((p) => p.vendor === product.vendor)
      );
      const remainingProducts = products.filter((product) => !uniqueArtistProducts.includes(product));
      const producstToDisplay =
        uniqueArtistProducts.length < 3 ? [...uniqueArtistProducts, ...remainingProducts] : uniqueArtistProducts;
      imagery[medium.value] = producstToDisplay.slice(0, 3);
    });
    await Promise.all(promises);
    setMediumImagery(imagery);
  }, [getProductsForChosenStyle]);

  const getStyleImagery = useCallback(async () => {
    const imagery: { [key: string]: PrintSetProductFragment[] } = {};
    const promises = styleFilterOptions.map(async (style) => {
      const { data } = await getProductsForChosenStyle({
        variables: {
          filters: [{ tag: style.value }, { tag: `gm.${style.value}` }],
          sortKey: ProductCollectionSortKeys.BestSelling,
          limit: 6,
        },
      });
      const products = data?.collection?.products.nodes || [];
      const uniqueArtistProducts = products.filter(
        (product, index, self) => index === self.findIndex((p) => p.vendor === product.vendor)
      );
      const remainingProducts = products.filter((product) => !uniqueArtistProducts.includes(product));
      const producstToDisplay =
        uniqueArtistProducts.length < 3 ? [...uniqueArtistProducts, ...remainingProducts] : uniqueArtistProducts;
      imagery[style.value] = producstToDisplay.slice(0, 3);
    });
    await Promise.all(promises);
    setStyleImagery(imagery);
  }, [getProductsForChosenStyle]);

  const applyComplimentaryProducts = async (products: PrintSetProductFragment[]) => {
    const incompletePrintSetProducts = products.filter((product) => printSets.every((p) => p.product.id !== product.id));
    const promises = incompletePrintSetProducts.map(async (product) => {
      const { data } = await getComplimentaryProducts({
        variables: {
          productId: product.id,
        },
      });
      const complimentaryProducts = (data?.productRecommendations || []).filter(
        (p) => p.tags.includes("favourite") && !products.some((prod) => prod.id === p.id)
      );
      const mostRelevantComplimentaryProduct = complimentaryProducts.find(
        (p) => selectedStyles.some((s) => p.tags.includes(`gm.${s}`)) && p.medium && selectedMediums.includes(p.medium.value)
      );
      const mostRelevantComplimentaryProductByStyle = complimentaryProducts.find((p) =>
        selectedStyles.some((s) => p.tags.includes(`gm.${s}`))
      );
      const mostRelevantComplimentaryProductByMedium = complimentaryProducts.find(
        (p) => p.medium && selectedMediums.includes(p.medium.value)
      );
      const complimentaryProduct =
        mostRelevantComplimentaryProduct ||
        mostRelevantComplimentaryProductByMedium ||
        mostRelevantComplimentaryProductByStyle ||
        complimentaryProducts[0];
      if (!complimentaryProduct) return null;
      return {
        link: `/print-set?products=${getIdNumber(product.id)},${getIdNumber(complimentaryProduct.id)}`,
        product,
        complimentaryProduct,
      };
    });
    return await Promise.all(promises);
  };

  const producePersonalisedPrintSets = async (loadMore = false) => {
    setLoadingPersonalised(true);
    if (loadMore) {
      const { data } = await fetchMorePersonalisedPrintSets({
        variables: {
          afterCursor: personalisedPrintSetsData?.collection?.products.pageInfo.endCursor,
        },
      });
      const printCombinations = await applyComplimentaryProducts(data?.collection?.products.nodes || []);
      setPersonalisedPrintSets((prev) => [...(prev || []), ...compact(printCombinations)]);
    } else {
      const { data } = await getPersonalisedPrintSets();
      const printCombinations = await applyComplimentaryProducts(data?.collection?.products.nodes || []);
      setPersonalisedPrintSets(compact(printCombinations));
    }
    setLoadingPersonalised(false);
  };

  useEffect(() => {
    if (isEmpty(mediumImagery)) {
      getMediumImagery();
    }
    if (isEmpty(styleImagery)) {
      getStyleImagery();
    }
  }, [mediumImagery, getMediumImagery, styleImagery, getStyleImagery]);

  useEffect(() => {
    if (data?.collections) {
      const defaultPrintSets = data.collections.nodes.map((collection) => {
        const products = collection.products.nodes;
        const product = products[0];
        const complimentaryProduct = products[1];
        return {
          link: `/print-set?products=${getIdNumber(product.id)},${getIdNumber(complimentaryProduct.id)}`,
          product,
          complimentaryProduct,
        };
      });
      setPrintSets(defaultPrintSets);
    }
  }, [data]);

  return {
    printSets,
    personalisedPrintSets,
    getMediumImagery,
    producePersonalisedPrintSets,
    selectedMediums,
    setSelectedMediums,
    selectedStyles,
    setSelectedStyles,
    mediumImagery,
    styleImagery,
    loading,
    loadingPersonalised,
    hasMore: data?.collections?.pageInfo.hasNextPage || false,
    hasMorePersonalised: personalisedPrintSetsData?.collection?.products.pageInfo.hasNextPage || false,
    fetchMore,
    fetchMorePersonalised: () => producePersonalisedPrintSets(true),
  };
};

export default usePrintSets;
