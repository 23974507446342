import { BasketFragment } from "../generated/storefront";

export const getBasketDiscountPct = (basket?: BasketFragment): number => {
  if (!basket || !basket.lines || !basket.lines.nodes.length) return 0;
  const basketMainItems = basket?.lines.nodes.filter((l) => l.merchandise.product.title !== "Mounting") || [];
  const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);

  if (basketCount === 2) return 0.15;
  if (basketCount >= 3) return 0.2;
  return 0;
};

export const getFutureDiscountPct = (basket?: BasketFragment): number => {
  if (!basket || !basket.lines || !basket.lines.nodes.length) return 0;
  const basketMainItems = basket?.lines.nodes.filter((l) => l.merchandise.product.title !== "Mounting") || [];
  const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);
  if (basketCount === 1) return 0.15;
  if (basketCount >= 2) return 0.2;
  return 0;
};
