import { addMonths, endOfMonth, format, formatDuration, intervalToDuration, isSameMonth, startOfMonth } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { enGB } from "date-fns/locale/en-GB";

export const isNextMonth = (date: string | Date) => {
  const dateLeft = new Date(date + "-15"); // to cater for change in tz
  return isSameMonth(dateLeft, addMonths(new Date(), 1));
};

export const getThisMonthsDate = () => {
  return format(new Date(), "yyyy-MM");
};

export const getMonthsDate = (month: Date | string) => {
  return format(new Date(month), "yyyy-MM");
};

export const getNextMonthsDate = () => {
  return format(addMonths(new Date(), 1), "yyyy-MM");
};

export const getThisMonth = () => {
  return format(new Date(), "MMMM");
};

export const getNextMonth = () => {
  return format(addMonths(new Date(), 1), "MMMM");
};

export const formatUKTimestamp = (date: Date) => {
  return formatInTimeZone(date, "Europe/London", "yyyy-MM-dd'T'HH:mm:ss'Z'", { locale: enGB });
};

export const getCountDown = () => {
  const duration = intervalToDuration({
    start: new Date(),
    end: endOfMonth(new Date()),
  });
  delete duration.seconds;
  return formatDuration(duration, {
    delimiter: ", ",
  });
};

export const monthStart = format(startOfMonth(new Date()), "yyyy-MM-dd");

export const today = format(new Date(), "yyyy-MM-dd");
