import axios from "axios";
import { CurrencyCode } from "../../generated/storefront";

type PaypalBalance = {
  currency: CurrencyCode;
  available_balance: { currency_code: CurrencyCode; value: string };
  total_balance: { currency_code: CurrencyCode; value: string };
};

export const getAmountsInLocalCurrencies = async (
  totals: { amount: number; currency: CurrencyCode }[]
): Promise<{ gbpAmount: number; localAmount: number; currency: CurrencyCode }[]> => {
  try {
    const result = await axios.post(`/.netlify/functions/getAmountsInCurrency`, totals);
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to convert amounts to local currency");
  }
};

export const getLocalAmountInGbp = async ({ amount, currency }: { amount: number; currency: CurrencyCode }): Promise<number> => {
  try {
    const result = await axios.post(`/.netlify/functions/getLocalAmountInGbp`, { amount, currency });
    return result.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to convert local amount to gbp");
  }
};

export const getPaypalBalances = async (): Promise<{ balances: PaypalBalance[] }> => {
  try {
    const result = await axios.post(`/.netlify/functions/getPaypalBalances`);
    return result.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
