import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { NavItem } from "../styles";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { colors } from "../../../theme";
import { useGetCollectionsByTypeQuery } from "../../../generated/storefront";
import { scrollbarStyles } from "../../../state/constants";

const CollectionMenu = ({ onCloseNav }: { onCloseNav: () => void }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { data: featuredCollectionData } = useGetCollectionsByTypeQuery({
    variables: { type: `title:featured` },
  });
  const collections = featuredCollectionData?.collections.nodes || [];

  return (
    <Box bgcolor={menuOpen ? colors.grey02 : colors.white}>
      <NavItem fontSize={18} onClick={() => setMenuOpen(!menuOpen)} paddingY={1.5} paddingX={2}>
        Collections {menuOpen ? <ExpandLessRounded fontSize="small" /> : <ExpandMoreRounded fontSize="small" />}
      </NavItem>
      {menuOpen && (
        <Stack direction="row" gap={1} paddingBottom={1.5} paddingX={2} overflow="auto" sx={scrollbarStyles}>
          {collections.map((collection) => (
            <Link to={`/shop/collections/${collection.handle}`} key={collection.id} onClick={onCloseNav}>
              <Stack gap={1}>
                <img
                  src={collection.image?.url + "&width=200"}
                  alt={collection.title}
                  width={70}
                  height={70}
                  style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
                />
                <Typography fontSize={12} align="center" textOverflow="ellipsis" noWrap width={70}>
                  {collection.title.split(": ").pop()}
                </Typography>
              </Stack>
            </Link>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default CollectionMenu;
