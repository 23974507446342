import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppState } from "./state";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import ArtistLogin from "./pages/Login/Artist";
import CustomerLogin from "./pages/Login/Customer";
import Sell from "./pages/Sell";
import Trade from "./pages/Trade";
import Blog from "./pages/Blog";
import Blogs from "./pages/Blogs";
import Upload from "./pages/Upload";
import About from "./pages/About";
import Artists from "./pages/Artists";
import Artist from "./pages/Artist";
import Contact from "./pages/Contact";
import ResetPassword from "./pages/ResetPassword";
import CustomerResetPassword from "./pages/CustomerResetPassword";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import Faqs from "./pages/FAQS";
import Privacy from "./pages/Privacy";
import Delivery from "./pages/Delivery";
import Terms from "./pages/Terms";
import HangingGuide from "./pages/HangingGuide";
import { getMarkets } from "./services/API";
import CookieBanner from "./components/CookieBanner";
import { CountryCode, useGetBasketLazyQuery, useUpdateBasketBuyerIdendityMutation } from "./generated/storefront";
import Wishlists from "./pages/Wishlists";
import Wishlist from "./pages/Wishlist";
import GalleryWalls from "./pages/GalleryWalls";
import GalleryWall from "./pages/GalleryWall";
import Search from "./pages/Search";
import Account from "./pages/Customer/Account";
import CustomerAuth from "./components/AuthGuard/CustomerAuth";
import GalleryWallBuilder from "./components/GalleryWallBuilder";
import GalleryWallBuilderPage from "./pages/GalleryWallBuilder";
import GiftGuide from "./pages/GiftGuide";
import PrintSets from "./pages/PrintSets";
import PrintSet from "./pages/PrintSet";
import RollingTextBanner from "./components/RollingTextBanner";
import { colors } from "./theme";
import { Box } from "@mui/material";

const App = () => {
  const {
    fixedNavHeight,
    isMobileScreen,
    basket,
    setBasket,
    availableMarkets,
    setAvailableMarkets,
    nearestMarket,
    loadNearestMarket,
    selectedCountry,
    setSelectedCountry,
    showCookieBanner,
    setShowCookieBanner,
    galleryWallBuilderIsOpen,
    toggleGalleryWallBuilderIsOpen,
  } = useAppState();
  const [updateBasketBuyerIdendity] = useUpdateBasketBuyerIdendityMutation();
  const [loadingMarkets, setLoadingMarkets] = useState<boolean>(false);
  const [loadingNearestMarket, setLoadingNearestMarket] = useState<boolean>(false);
  const [getBasket] = useGetBasketLazyQuery();

  const loadBasket = async () => {
    const basketId = localStorage.getItem("basketId");
    if (basketId) {
      const { data } = await getBasket({ variables: { basketId } });
      if (data?.cart) setBasket(data.cart);
    }
  };

  const setMarkets = async () => {
    setLoadingMarkets(true);
    const markets = await getMarkets();
    setAvailableMarkets(markets);
    setLoadingMarkets(false);
  };

  const setNearestMarket = async () => {
    if (!nearestMarket && !loadingNearestMarket) {
      setLoadingNearestMarket(true);
      await loadNearestMarket();
      setLoadingNearestMarket(false);
    }
  };

  const updateBasketCountry = async () => {
    if (basket?.buyerIdentity && basket.buyerIdentity.countryCode !== selectedCountry) {
      const { data } = await updateBasketBuyerIdendity({
        variables: { basketId: basket.id, buyerIdentity: { countryCode: selectedCountry } },
      });
      if (data?.cartBuyerIdentityUpdate?.cart) setBasket(data.cartBuyerIdentityUpdate.cart);
    }
  };

  const handleCookieBannerClose = () => {
    localStorage.setItem("closedCookieBanner", "true");
    setShowCookieBanner(false);
  };

  useEffect(() => {
    if (!basket) {
      loadBasket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  useEffect(() => {
    if (!availableMarkets && !loadingMarkets) {
      setMarkets();
    }
  });

  useEffect(() => {
    setNearestMarket();
  });

  useEffect(() => {
    if (basket) {
      setSelectedCountry(basket.buyerIdentity?.countryCode || CountryCode.Gb);
    } else if (nearestMarket) {
      setSelectedCountry(nearestMarket.regions.nodes[0]?.code || CountryCode.Gb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket, nearestMarket]);

  useEffect(() => {
    updateBasketCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <div className="App">
      <Box position="relative" zIndex={1}>
        <RollingTextBanner
          text="Delivery on us 🎄 This week only ⏰ "
          background={colors.christmasRed}
          color={colors.white}
          padding={10}
          size={isMobileScreen ? 10 : 14}
          speed={40}
        />
      </Box>
      <Nav />
      {/* <Margin margin={navHeight} /> */}
      <div style={{ minHeight: `calc(100vh - ${fixedNavHeight}px)` }}>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/artists/:id" element={<Artist />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/gift-guide" element={<GiftGuide />} />
          <Route path="/goodnews/:id" element={<Blog />} />
          <Route path="/goodnews" element={<Blogs />} />
          <Route path="/collections/:id" element={<GalleryWall />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/hanging-guide" element={<HangingGuide />} />
          <Route path="/gallery-walls" element={<GalleryWalls />} />
          <Route path="/gallery-wall-builder" element={<GalleryWallBuilderPage />} />
          <Route path="/login/artist" element={<ArtistLogin />} />
          <Route path="/login/customer" element={<CustomerLogin />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/products/:id" element={<Product />} />
          <Route path="/print-sets" element={<PrintSets />} />
          <Route path="/print-set" element={<PrintSet />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/customer" element={<CustomerResetPassword />} />
          <Route path="/search" element={<Search />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/collections/:handle" element={<Shop />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/wishlist" element={<Wishlists />} />
          <Route path="/wishlist/:id" element={<CustomerAuth element={Wishlist} />} />
          <Route path="/customer/account" element={<CustomerAuth element={Account} />} />
          <Route path="/" element={<Home />} />
          <Route element={<NotFound />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
      {showCookieBanner && <CookieBanner onClose={handleCookieBannerClose} />}
      {galleryWallBuilderIsOpen && (
        <GalleryWallBuilder drawerIsOpen={galleryWallBuilderIsOpen} toggleDrawerIsOpen={toggleGalleryWallBuilderIsOpen} />
      )}
    </div>
  );
};

export default App;
