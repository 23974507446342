import { useState, useEffect, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import Button from "../Button";
import TextLink from "../TextLink";

function useErrorBoundary(): { hasError: boolean; error: Error | null } {
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      setHasError(true);
      setError(event.error);
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return { hasError, error };
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

function ErrorBoundary({ children }: ErrorBoundaryProps): JSX.Element {
  const { hasError } = useErrorBoundary();

  if (hasError) {
    return (
      <Stack justifyContent="center" paddingY={6} paddingX={4} gap={3}>
        <Typography variant="h3" align="center">
          Oops! Looks like something went wrong
        </Typography>
        <Typography align="center">
          Please try again. If the problem persists, please contact us at{" "}
          <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
        </Typography>
        <Stack gap={1} direction={{ xs: "column", md: "row" }} justifyContent="center">
          <Button onClick={() => window.location.reload()}>Reload</Button>
          <Button secondary onClick={() => window.location.replace("/shop")}>
            Go to shop
          </Button>
        </Stack>
      </Stack>
    );
  }

  return <>{children}</>;
}

export default ErrorBoundary;
