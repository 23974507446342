import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Skeleton, Stack, Typography } from "@mui/material";
import FrameMockUp from "../../FrameMockUp";
import { SearchProductFragment } from "../../../generated/storefront";
import { colors } from "../../../theme";
import { giftCardId } from "../../../state/constants";
import { Frame } from "../../../types/product";

export type Props = {
  product: SearchProductFragment;
};

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Card = styled.div`
  position: relative;
  background-color: ${colors.grey02};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-grow: 1;
`;

const SearchProductCard = ({ product }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const giftCard = product.id === giftCardId;
  const image = product.images.nodes[0];

  if (giftCard)
    return (
      <Stack gap={1} height="100%" key={product.id} minHeight={200}>
        <Link to={`/products/${product.handle}`} style={{ height: "100%", zIndex: 0 }}>
          <Wrapper>
            <Card>{product.images.nodes.length > 0 && <FrameMockUp frame={Frame.Unframed} image={image} />}</Card>

            <Stack paddingTop={1}>
              <Typography
                variant="body2"
                fontSize={12}
                textOverflow="ellipsis"
                overflow="hidden"
                style={{ whiteSpace: "nowrap" }}
              >
                {product.title}
              </Typography>
            </Stack>
          </Wrapper>
        </Link>
      </Stack>
    );

  return (
    <Stack gap={1} height="100%" key={product.id} minHeight={{ xs: 180, md: 220 }}>
      <Link to={`/products/${product.handle}`} style={{ height: "100%", zIndex: 0 }}>
        <Wrapper>
          <Card>
            {!imageLoaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ position: "absolute", bgcolor: colors.grey02 }}
              />
            )}
            <FrameMockUp frame={Frame.Unframed} image={image} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded} />
          </Card>
          <Stack paddingTop={1}>
            <Typography variant="body2" fontSize={12} textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
              {product.title}
            </Typography>
          </Stack>
        </Wrapper>
      </Link>
    </Stack>
  );
};

export default SearchProductCard;
