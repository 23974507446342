import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import Dashboard from "./Dashboard";
import Details from "./Details";
import AuthContext from "../../state/auth";
import { User } from "../../types/user";
import Assets from "./Assets";
import SideBar from "./SideBar";
// import Settings from "./Settings";
import Articles from "./Articles";
import { useAppState } from "../../state";

const Profile = () => {
  const { hash } = useLocation();
  const { isMobileScreen, toggleGalleryWallBuilderIsOpen } = useAppState();
  const tab = hash.replace("#", "");
  const { user: authUser } = useContext(AuthContext);
  const [user, setUser] = useState<User | null>(authUser);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location.state?.registered ? "details" : tab || "dashboard");
  const [imageUrl, setImageUrl] = useState<string>("");

  const onTabChange = (tab: number | string) => {
    window.location.hash = tab as string;
    setSelectedTab(tab as string);
  };

  useEffect(() => {
    setUser(authUser);
  }, [authUser]);

  return user ? (
    <>
      <Helmet>
        <title>{`${user.firstName} ${user.lastName} Profile | GoodMood`}</title>
      </Helmet>
      <Box>
        <SideBar selectedTab={selectedTab} onTabChange={onTabChange} />
        <Stack direction="row" justifyContent="flex-end">
          <Stack width={{ xs: "100%", md: "calc(100% - 300px)" }}>
            {selectedTab === "dashboard" && <Dashboard user={user} isMobileScreen={isMobileScreen} />}
            {selectedTab === "details" && (
              <Details
                user={user}
                setUser={setUser}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                isMobileScreen={isMobileScreen}
              />
            )}
            {selectedTab === "assets" && (
              <Assets
                user={user}
                toggleGalleryWallBuilderIsOpen={toggleGalleryWallBuilderIsOpen}
                isMobileScreen={isMobileScreen}
              />
            )}
            {selectedTab === "articles" && <Articles />}
            {/* {selectedTab === "settings" && <Settings />} */}
          </Stack>
        </Stack>
      </Box>
    </>
  ) : null;
};

export default Profile;
