import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import FrameMockUp from "../../components/FrameMockUp";
import plainWall from "../../assets/images/mock-ups/plain-wall.webp";
import { PrintSet } from "./usePrintSets";
import { Frame } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { truncatedTextStyles } from "../../state/constants";
import { colors } from "../../theme";
import { CurrencyCode } from "../../generated/storefront";

const PrintSetCard = ({ printSet, selectedFrame }: { printSet: PrintSet; selectedFrame: Frame }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const currencyCode = printSet.product.priceRange.minVariantPrice.currencyCode;
  const showDecimals = currencyCode === CurrencyCode.Eur;
  const totalMinPrice =
    Number(printSet.product.priceRange.minVariantPrice.amount) +
    Number(printSet.complimentaryProduct.priceRange.minVariantPrice.amount);

  return (
    <Link to={printSet.link} target="_blank">
      <Box position="relative" minHeight={{ xs: "45vw", md: "30vw", xxl: "10vw" }} width="100%">
        {!imageLoaded && (
          <Skeleton
            width="100%"
            height="100%"
            animation="wave"
            variant="rectangular"
            sx={{ position: "absolute", bgcolor: colors.grey02 }}
          />
        )}
        <img src={plainWall} alt="plain wall" width="100%" height="auto" onLoad={() => setImageLoaded(true)} />
        <Stack
          direction="row"
          gap={{ xs: 1, md: 2 }}
          position="absolute"
          top="50%"
          left="50%"
          width="100%"
          padding={{ xs: 2, md: 5 }}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <Box paddingBottom={{ xs: 5, md: 9 }} width="50%">
            <FrameMockUp frame={selectedFrame} image={printSet.product.images.nodes[0]} shadow />
          </Box>

          <Box paddingTop={{ xs: 5, md: 9 }} width="50%">
            <FrameMockUp frame={selectedFrame} image={printSet.complimentaryProduct.images.nodes[0]} shadow />
          </Box>
        </Stack>
      </Box>
      <Stack gap={0.5} paddingX={{ xs: 2, md: 0 }}>
        <Typography variant="body2" {...truncatedTextStyles}>
          {printSet.product.title} · {printSet.complimentaryProduct.title}
        </Typography>
        <Stack gap={{ xs: 0.75, md: 1 }} direction="row">
          <Stack direction="row" gap={{ xs: 0.5, md: 0.75 }}>
            <Typography variant="body2">From</Typography>
            <Typography variant="body2" style={{ textDecoration: "line-through" }} color="text.disabled">
              {formattedPrice(totalMinPrice, currencyCode, showDecimals)}
            </Typography>
          </Stack>

          <Typography variant="body2" color={colors.red}>
            {formattedPrice(totalMinPrice * (1 - 0.15), currencyCode, showDecimals)} (-15%)
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export default PrintSetCard;
