import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Skeleton, Stack, Typography } from "@mui/material";
import { formattedPrice } from "../../helpers/money";
import { colors } from "../../theme";
import { Frame } from "../../types/product";
import FrameMockUp from "../FrameMockUp";
import { media } from "../../helpers/layout";
import RollingTextBanner from "../RollingTextBanner";
import { useAppState } from "../../state";
import AuthContext from "../../state/auth";
import { CountryCode } from "../../generated/storefront";
import { AdminProductFragment } from "../../generated/graphql";
import WishlistBookmark from "../WishlistBookmark";
import AdminSection from "./AdminSection";
import { getIdNumber } from "../../helpers/shopify";

export type ProductCardProduct = AdminProductFragment & {
  collections?: { nodes: { id: string; title: string }[] };
};

export type Props = {
  product: ProductCardProduct;
  minHeight?: string;
  nextMonths?: boolean;
  indent?: boolean;
  frame?: Frame;
  refetch?: () => void;
};

export const Card = styled.div`
  position: relative;
  background-color: ${colors.grey02};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-grow: 1;
  min-height: 220px;

  @media ${media.m} {
    padding: 24px;
  }
`;

const AdminProductCard = ({ product, minHeight, nextMonths = false, indent, frame = Frame.Natural }: Props) => {
  const { isMobileScreen, selectedCountry } = useAppState();
  const { customer, refetchCustomer, isAdmin } = useContext(AuthContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const image = product.images.nodes[0];
  const { minVariantPrice } = product.priceRangeV2;
  const showDecimals = selectedCountry === CountryCode.At;
  const nextMonthText = "coming soon · next month · ";
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  return (
    <Stack gap={1} minHeight={minHeight} height={"100%"}>
      <Link
        to={`/products/${getIdNumber(product.id)}`}
        style={{ height: "100%", zIndex: 0 }}
        onClick={(e) => popoverOpen && e.preventDefault()}
      >
        <Stack height="100%" width="100%">
          {nextMonths && (
            <RollingTextBanner
              text={nextMonthText}
              background={colors.grey90}
              color={colors.white}
              size={isMobileScreen ? 6 : 14}
              speed={50}
            />
          )}
          <Card>
            {!imageLoaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ position: "absolute", bgcolor: colors.grey02 }}
              />
            )}
            <FrameMockUp frame={frame} image={image} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded} />
          </Card>
          {nextMonths && (
            <RollingTextBanner
              text={nextMonthText}
              background={colors.grey90}
              color={colors.white}
              size={isMobileScreen ? 6 : 14}
              speed={50}
            />
          )}
          <Stack gap={{ xs: 0.2, md: 0.25 }} paddingX={indent ? 1.5 : 0} paddingTop={2}>
            <Stack gap={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                {product.title}
              </Typography>
              <WishlistBookmark
                productId={product.id}
                customer={customer}
                refetchCustomer={refetchCustomer}
                popoverOpen={popoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            </Stack>

            <Stack gap={{ xs: 0.3, md: 0.5 }}>
              <Link to={`/artists/${product.vendor}`}>
                <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                  {product.artistName?.value || ""}
                </Typography>
              </Link>
              <Stack gap={{ xs: 0.75, md: 1 }} direction="row">
                <Stack direction="row" gap={{ xs: 0.5, md: 0.75 }}>
                  <Typography variant="body2">From</Typography>
                  <Typography variant="body2">
                    {formattedPrice(minVariantPrice.amount, minVariantPrice.currencyCode, showDecimals)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Link>
      {isAdmin && <AdminSection product={product} />}
    </Stack>
  );
};

export default AdminProductCard;
