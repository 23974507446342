import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

export const colors = {
  black: "#070707",
  white: "#FFFFFF",
  snowWhite: "#F9F9F9",
  cream: "#FFFEFA",
  lemon: "#FFF8CA",
  purple: "#E9D0FE",
  darkPurple: "#7A06DE",
  yellow: "#FFE619",
  mint: "#D7FFC3",
  beige: "#F6EEE0",
  red: "#F20000",
  christmasRed: "#D30909",
  error: "#E30303",
  errorLight: "#FCE6E6",
  errorDark: "#B61D0E",
  cardGrey: "#F6F6F6",
  grey90: "#1A1A1A",
  grey80: "#333333",
  grey60: "#666666",
  grey40: "#999999",
  grey20: "#CCCCCC",
  grey10: "#E6E6E6",
  grey05: "#F2F2F2",
  grey02: "#FAFAFA",
  purple80: "#E5D4F3",
  purple60: "#EBDFF6",
  purple40: "#F2E9F9",
  purple20: "#F8F4FC",
  purple10: "#FCFAFE",
  peach: "#FFD2A9",
  salmon: "#EDA592",
  blue: "#D3EAFF",
  green: "#008D1F",
  gold: "#F0B64E",
  neon: "#C3FF5A",
  grey: "#E9E8E2",
} as const;

export const fonts = {
  brand: "WhyteInktrapRegular, Helvetica, Arial, sans-serif",
  banner: "PPFraktionMonoRegular",
  articles: "PTRootUIBold",
  disclaimer: "PTRootUIMedium",
  body: "Helvetica Neue, Helvetica, Arial, sans-serif",
} as const;

export const breakpoints = {
  s: 600,
  m: 900,
  l: 1200,
} as const;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
      xxl: 1800,
      xxxl: 2100,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily: fonts.brand,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto",
          padding: "4px 8px",
          borderRadius: 24,
          gap: 8,
          ".MuiChip-label": {
            fontFamily: fonts.body,
            padding: 0,
          },
          ".MuiSvgIcon-root": {
            marginRight: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: colors.grey10,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          backgroundColor: colors.grey02,
          ":hover": {
            backgroundColor: colors.grey05,
          },
          ":focus": {
            backgroundColor: "transparent",
          },
          "&.Mui-focused": {
            backgroundColor: colors.grey05,
          },
          ":before": {
            display: "none",
          },
          ":after": {
            display: "none",
          },
        },
        input: {
          ":-webkit-autofill": {
            borderRadius: 0,
          },
          fontFamily: fonts.brand,
          fontSize: "16px",
          border: 0,
          borderRadius: "100px",
          padding: "10px 16px",
          ":focus": {
            backgroundColor: "transparent",
          },
        },
        inputSizeSmall: {
          padding: "6px 16px",
          fontSize: "12px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily: fonts.brand,
          border: `1px solid ${colors.grey10}`,
          borderRadius: 8,
          ":focus": {
            borderColor: colors.grey40,
          },
          ":hover": {
            borderColor: colors.grey40,
          },
        },
        input: {
          padding: "12px 16px",
          display: "flex",
          alignItems: "center",
          lineHeight: "100%",
          "::placeholder": {
            color: colors.grey20,
            opacity: 1,
          },
        },
        notchedOutline: {
          border: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minWidth: "100px",
          minHeight: "auto",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
        nativeInput: {
          height: "100%",
          fontSize: "14px",
        },
        icon: {
          color: colors.black,
          fontSize: "16px",
          right: "12px",
        },
        select: {
          display: "inline-flex",
          alignItems: "center",
          fontSize: "14px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          fontFamily: fonts.body,
          boxShadow: "0 0 #0000,0 0 #0000,0 0 #0000,0 0 #0000),0 8px 24px rgba(0,0,0,.12)",
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: fonts.body,
          fontSize: "14px",
          padding: "8px",
          borderRadius: 8,
        },
        selected: {
          backgroundColor: "rgba(7, 7, 7, 0.04)",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: 12,
          fontFamily: fonts.body,
          padding: 8,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: 8,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.white,
          color: colors.black,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
          padding: "16px",
        },
        arrow: {
          color: colors.white,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "10px",
          height: "15px",
          minWidth: "15px",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: 8,
        },
        label: {
          fontSize: 14,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&.Mui-expanded": {
            margin: "0",
            "::before": {
              opacity: 1,
            },
          },
          "::before": {
            backgroundColor: colors.grey05,
          },
          "::after": {
            display: "block",
            position: "absolute",
            left: "0",
            bottom: "0",
            right: "0",
            height: "1px",
            content: '""',
            backgroundColor: colors.grey05,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        content: {
          margin: "16px 0",
          "&.Mui-expanded": {
            margin: "16px 0",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
          margin: 16,
          width: "calc(100% - 32px)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":disabled": {
            backgroundColor: colors.white,
          },
          ":hover": {
            backgroundColor: colors.white,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: fonts.brand,
    fontSize: 16,
    fontWeightRegular: 400,
    h1: {
      fontSize: 52,
      lineHeight: "120%",
    },
    h2: {
      fontSize: 28,
      lineHeight: "140%",
      "@media (max-width:900px)": {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 24,
      lineHeight: "140%",
      "@media (max-width:900px)": {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 20,
      lineHeight: "125%",
      "@media (max-width:900px)": {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 18,
      lineHeight: "140%",
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "140%",
      fontFamily: fonts.body,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: "140%",
      fontFamily: fonts.body,
      fontWeight: 400,
    },
    body1: {
      fontFamily: fonts.brand,
      fontSize: 16,
      lineHeight: "145%",
      letterSpacing: "0.2px",
    },
    body2: {
      fontFamily: fonts.body,
      fontSize: 16,
      lineHeight: "130%",
      letterSpacing: "0.2px",
      "@media (max-width:900px)": {
        fontSize: 14,
      },
    },
  },
  palette: {
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.white,
    },
    info: {
      main: colors.purple,
    },
    success: {
      main: colors.mint,
    },
    text: {
      primary: colors.black,
      secondary: colors.grey80,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
    },
  },
});
