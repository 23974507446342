import axios from "axios";

export const getPageViews = async ({
  page,
  id,
  handle,
  startDate,
  endDate,
}: {
  page: "artists" | "products";
  id?: string;
  handle?: string;
  startDate?: string;
  endDate?: string;
}) => {
  try {
    return await axios.post("/.netlify/functions/getPageViews", { page, id, handle, startDate, endDate });
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
