import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { scrollbarStyles } from "../../state/constants";
import { useGetFeaturedCollectionsQuery, useGetShopCollectionQuery } from "../../generated/storefront";
import { colors } from "../../theme";

const FeaturedCollections = ({ collectionCategory, isMobileScreen }: { collectionCategory: string; isMobileScreen: boolean }) => {
  const { data: shopCollectionData } = useGetShopCollectionQuery();
  const { data: featuredCollectionsData } = useGetFeaturedCollectionsQuery({
    variables: { query: `title:${collectionCategory}` },
  });
  const shopCollection = shopCollectionData?.collection;
  const featuredCollections = featuredCollectionsData?.collections.nodes || [];

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Stack
        direction="row"
        gap={{ xs: 1, md: 1.5 }}
        padding={{ xs: "0 24px 16px", md: "0 40px 20px" }}
        sx={{ overflowX: "auto", ...scrollbarStyles }}
      >
        <Link to="/shop">
          <Box>
            {shopCollection?.image && (
              <img
                src={shopCollection.image.url}
                alt={shopCollection.image.altText || shopCollection.title.split(": ").pop()}
                width={isMobileScreen ? 100 : 190}
                height="auto"
              />
            )}
            <Typography
              marginTop={{ xs: 0.75, md: 1 }}
              align="center"
              fontSize={{ xs: 14, md: 16 }}
              width={isMobileScreen ? 100 : 190}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
            >
              All
            </Typography>
          </Box>
        </Link>
        {featuredCollections.map((collection) => (
          <Link key={collection.handle} to={`/shop/collections/${collection.handle}`}>
            <Box key={collection.handle}>
              {collection.image ? (
                <img
                  src={collection.image.url}
                  alt={collection.image.altText || collection.title.split(": ").pop()}
                  width={isMobileScreen ? 100 : 190}
                  height="auto"
                />
              ) : (
                <Box
                  bgcolor={colors.grey20}
                  width={isMobileScreen ? 100 : 190}
                  height={isMobileScreen ? 105 : 195}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                ></Box>
              )}
              <Typography
                marginTop={{ xs: 0.75, md: 1 }}
                align="center"
                fontSize={{ xs: 14, md: 16 }}
                width={isMobileScreen ? 100 : 190}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
              >
                {collection.title.split(": ").pop()}
              </Typography>
            </Box>
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};

export default FeaturedCollections;
