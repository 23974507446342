import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = new HttpLink({
  uri: "https://goodmoodprints.myshopify.com/api/2024-01/graphql",
});

const storeFrontMiddlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN,
  },
}));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        products: {
          keyArgs: (_, context) => {
            const { sortKey, reverse, query, country, variantLimit } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query, country, variantLimit });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const result = rest;
            result.nodes = [...existing.nodes, ...nodes];
            return result;
          },
        },
        search: {
          keyArgs: (_, context) => {
            const { sortKey, reverse, query, country, variantLimit } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query, country, variantLimit });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const result = rest;
            result.nodes = [...existing.nodes, ...nodes];
            return result;
          },
        },
        collections: {
          keyArgs: (args) => {
            const { sortKey, reverse, query } = args as any;
            return JSON.stringify({ sortKey, reverse, query });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const result = rest;
            result.nodes = [...existing.nodes, ...nodes];
            return result;
          },
        },
        collection: {
          keyArgs: (args, context) => {
            const { sortKey, reverse, filters, country, variantLimit } = context.variables as any;
            return JSON.stringify({ args, sortKey, reverse, filters, country, variantLimit });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const existingProductKey = Object.keys(existing).find((key) => key.startsWith("products"));
            if (!existingProductKey) return incoming;
            const existingProducts = existing[existingProductKey];
            const incomingProductKey = Object.keys(incoming).find((key) => key.startsWith("products"));
            if (!incomingProductKey) return existing;

            const { [incomingProductKey]: incomingProducts, ...restIncoming } = incoming;
            const { nodes, ...rest } = incomingProducts;
            const result = rest;
            result.nodes = [...existingProducts.nodes, ...nodes];

            return { ...restIncoming, [existingProductKey]: result };
          },
        },
        orders: {
          keyArgs: (_, context) => {
            const { sortKey, reverse, query } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const result = rest;
            result.nodes = [...existing.nodes, ...nodes];
            return result;
          },
        },
      },
    },
  },
});

export const storefrontClient = new ApolloClient({
  link: storeFrontMiddlewareLink.concat(httpLink),
  cache,
});

export const adminClient = new ApolloClient({
  uri: "/.netlify/functions/graphql",
  cache,
});
