import { Link } from "react-router-dom";
import { subMonths } from "date-fns";
import { Box, Stack, Typography } from "@mui/material";
import { useGetCollectionsByTypeQuery } from "../../../generated/storefront";
import { colors, fonts } from "../../../theme";
import { getMonthsDate, getThisMonthsDate } from "../../../helpers/time";

const ShopMegaMenu = ({ closeMegaMenu }: { closeMegaMenu: boolean }) => {
  const { data: featuredCollectionData } = useGetCollectionsByTypeQuery({
    variables: { type: `title:featured` },
  });
  const { data: roomCollectionData } = useGetCollectionsByTypeQuery({
    variables: { type: `title:by room` },
  });
  const collections = featuredCollectionData?.collections.nodes || [];
  const roomCollections = roomCollectionData?.collections.nodes || [];

  return (
    <Box
      paddingY={3.2}
      position="absolute"
      className={`shop-mega-menu` + (closeMegaMenu ? " close" : "")}
      display="none"
      bottom={0}
      left="-100%"
      width="auto"
      minWidth={845}
      maxWidth="100%"
      maxHeight={640}
      sx={{ transform: "translate(0%, 100%)" }}
    >
      <Box bgcolor={colors.white} borderRadius="0 0 24px 24px" overflow="hidden">
        <Stack direction="row" height="100%">
          <Stack padding={2} gap={2} bgcolor={colors.grey02}>
            <Typography fontFamily={fonts.brand} fontSize={10} color={colors.grey40} textTransform="uppercase">
              Popular
            </Typography>
            <Link to="/shop">
              <Typography>All art prints</Typography>
            </Link>
            <Link to="/shop/collections/community-favourites">
              <Typography>Community favourites</Typography>
            </Link>
            <Link
              to={`/shop?month=${getThisMonthsDate()},${getMonthsDate(subMonths(new Date(), 1))},${getMonthsDate(
                subMonths(new Date(), 2)
              )},${getMonthsDate(subMonths(new Date(), 3))}`}
            >
              <Typography>Trending</Typography>
            </Link>
            <Link to={"/shop?month=" + getThisMonthsDate()}>
              <Typography>New</Typography>
            </Link>
            <Link to="/products/gift-card">
              <Typography>Gift card</Typography>
            </Link>
            <Link to="/gift-guide">
              <Typography>Gift guide</Typography>
            </Link>
            <Link to="/gallery-wall-builder">
              <Typography>Gallery wall builder</Typography>
            </Link>
          </Stack>
          <Stack flex={1} gap={2} padding={2} justifyContent="space-around" overflow="auto">
            <Stack direction="row" gap={4}>
              <Typography
                fontFamily={fonts.brand}
                fontSize={10}
                color={colors.grey40}
                textTransform="uppercase"
                flexBasis={"66%"}
              >
                By Collection
              </Typography>
              <Typography
                fontFamily={fonts.brand}
                fontSize={10}
                color={colors.grey40}
                textTransform="uppercase"
                flexBasis={"33%"}
              >
                By Room
              </Typography>
            </Stack>

            <Stack direction="row" gap={4} height={"-webkit-fill-available"}>
              <Box gap={1.5} flexBasis={"66%"} height="100%" sx={{ columnCount: 2 }}>
                {collections.map((collection) => (
                  <Link key={collection.handle} to={`/shop/collections/${collection.handle}`}>
                    <Stack direction="row" alignItems="center" gap={1.5} paddingBottom={1}>
                      <img
                        src={collection.image?.url + "&width=400"}
                        alt={collection.image?.altText || collection.title.split(": ").pop()}
                        width={56}
                        height={56}
                        style={{ borderRadius: 5, objectFit: "cover" }}
                      />
                      <Typography>{collection.title.split(": ").pop()}</Typography>
                    </Stack>
                  </Link>
                ))}
              </Box>

              <Stack gap={1.5} flexBasis={"33%"}>
                {roomCollections.map((collection) => (
                  <Link key={collection.handle} to={`/shop/collections/${collection.handle}`}>
                    <Stack direction="row" alignItems="center" gap={1.5}>
                      <img
                        src={collection.image?.url + "&width=400"}
                        alt={collection.image?.altText || collection.title.split(": ").pop()}
                        width={56}
                        height={56}
                        style={{ borderRadius: 5, objectFit: "cover" }}
                      />
                      <Typography>{collection.title.split(": ").pop()}</Typography>
                    </Stack>
                  </Link>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ShopMegaMenu;
