import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import placeholder from "../../assets/images/placeholder.webp";
import { User } from "../../types/user";
import { colors } from "../../theme";
import LazyLoadImage from "../Image/LazyLoadImage";

type Props = {
  artist: User;
  size?: "small" | "medium";
  imageHeight?: string;
  borderRadius?: number;
};

const ArtistCard = ({ artist, size = "medium", imageHeight, borderRadius }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link to={`/artists/${artist.permalink || artist.id}`} key={artist.id}>
      <Stack height="100%" gap={1}>
        <Box height={imageHeight} width="100%" position="relative">
          {!imageLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              animation="wave"
              variant="rectangular"
              sx={{ position: "absolute", bgcolor: colors.grey02 }}
              style={{ borderRadius }}
            />
          )}
          <LazyLoadImage
            src={artist.image || placeholder}
            alt={`${artist.firstName} ${artist.lastName}`}
            width="100%"
            height="100%"
            onLoad={() => setImageLoaded(true)}
            style={{ borderRadius, objectFit: "cover" }}
          />
        </Box>
        <Typography variant="body2" fontSize={size === "small" ? 12 : 16}>
          {artist.firstName} {artist.lastName}
        </Typography>
      </Stack>
    </Link>
  );
};

export default ArtistCard;
