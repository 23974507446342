import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { Image } from "../../components";
import { colors } from "../../theme";
import Button from "../../components/Button";
import { truncateThreeLinesStyles } from "../../state/constants";

const Articles = () => {
  const articles = [
    {
      id: 659206504662,
      title: "Make selling your work on GoodMood a success",
      image: "https://cdn.shopify.com/s/files/1/0619/3417/4422/articles/playful_typography.webp?v=1728450825",
      excerpt:
        "If you're an artist looking for a platform that empowers you to take control of your creative business, GoodMood is your go-to marketplace. We provide the tools you need to sell, market, and showcase your art, all while keeping a larger share of the profits compared to other platforms.",
    },
    {
      id: 659116589270,
      title: "Bringing your vision to life: the GoodMood and Bendito Mockup Collab",
      image: "https://cdn.shopify.com/s/files/1/0619/3417/4422/articles/Cover.png?v=1726140450",
      excerpt:
        "Introducing 'Make It Matte', a special collection of 58 art poster mockups by Bendito Mockups and frames by GoodMood. Perfect for showcasing designs, with five free mockups included. And hey, why not turn your visuals into reality and sell your posters on GoodMood!",
    },
    {
      id: 659121668310,
      title: "What is a Giclée Art Print? A Complete Guide",
      image: "https://cdn.shopify.com/s/files/1/0619/3417/4422/articles/prints.webp?v=1726238843",
      excerpt:
        "Giclée art prints are highly regarded in the world of fine art, known for their exceptional quality, durability, and vibrancy. But what exactly is a giclée print, and how does it differ from a regular art print?",
    },
  ];

  const emailBody = encodeURIComponent(
    `Hey,\n\nI'd like to submit a blog post to GoodMood. Here is the content:\n\nHere are some images I would like to include:`
  );

  const openEmail = () => {
    window.open(`mailto:nick@goodmoodprints.io?subject=Blog Submission&body=${emailBody}`);
  };

  return (
    <Box paddingTop={2} paddingBottom={12} paddingRight={{ xs: 2, md: 5 }} paddingLeft={{ xs: 2, md: 0 }}>
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
        <Typography variant="h2">Articles</Typography>
        <Button onClick={openEmail} secondary size="medium">
          Submit your own
        </Button>
      </Stack>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
        {articles.map((article) => (
          <Link to={`/goodnews/${article.id}`} style={{ flex: 1 }}>
            <Stack height={{ xs: "80vw", md: "24vw" }} overflow="hidden">
              <Image src={article.image} alt={article.title} />
            </Stack>
            <Stack gap={2} paddingTop={{ xs: 2, md: 2 }}>
              <Stack gap={1}>
                <Typography variant="h4">{article.title}</Typography>
                <Typography variant="subtitle2" color={colors.grey60} style={truncateThreeLinesStyles}>
                  {article.excerpt}
                </Typography>
              </Stack>
            </Stack>
          </Link>
        ))}
      </Stack>
    </Box>
  );
};

export default Articles;
