import { IconButton, MenuItem, Popover, Typography } from "@mui/material";
import { MoreHorizRounded } from "@mui/icons-material";
import { DashboardProductFragment, ProductStatus } from "../../../generated/graphql";
import { useState } from "react";
import { colors } from "../../../theme";

type ActionProps = {
  product: DashboardProductFragment;
  updateStatus: (status: ProductStatus) => void;
  loading: boolean;
};

const ActionButtons = ({ product, updateStatus, loading }: ActionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        color="primary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        style={{ width: 40, height: 40, backgroundColor: colors.grey02 }}
      >
        <MoreHorizRounded fontSize="inherit" />
      </IconButton>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            minWidth: 200,
          },
        }}
      >
        {product.status !== ProductStatus.Active && (
          <MenuItem onClick={() => updateStatus(ProductStatus.Active)}>
            <Typography display="flex" alignItems="center">
              Publish
            </Typography>
          </MenuItem>
        )}
        {product.status !== ProductStatus.Archived && (
          <MenuItem onClick={() => updateStatus(ProductStatus.Archived)}>
            <Typography display="flex" alignItems="center" color="error">
              Archive
            </Typography>
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

export default ActionButtons;
