import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import {
  AdUnitsOutlined,
  AdUnitsRounded,
  BarChartRounded,
  FolderOpenOutlined,
  FolderOpenRounded,
  PersonOutlineRounded,
  PersonRounded,
  // SettingsOutlined,
  // SettingsRounded,
} from "@mui/icons-material";
import { colors } from "../../theme";
import { authSignOutUser } from "../../services/Firebase";
import Button from "../../components/Button";
import { useAppState } from "../../state";

type Props = {
  selectedTab: string;
  onTabChange: (tab: string) => void;
};

const tabs = [
  {
    icon: BarChartRounded,
    filledIcon: BarChartRounded,
    label: "Dashboard",
    value: "dashboard",
  },
  {
    icon: PersonOutlineRounded,
    filledIcon: PersonRounded,
    label: "Details",
    value: "details",
  },
  {
    icon: AdUnitsOutlined,
    filledIcon: AdUnitsRounded,
    label: "Assets",
    value: "assets",
  },
  {
    icon: FolderOpenOutlined,
    filledIcon: FolderOpenRounded,
    label: "Articles",
    value: "articles",
  },
  // {
  //   icon: SettingsOutlined,
  //   filledIcon: SettingsRounded,
  //   label: "Settings",
  //   value: "settings",
  // },
];

const SideBar = ({ selectedTab, onTabChange }: Props) => {
  const navigate = useNavigate();
  const { navHeight, isMobileScreen } = useAppState();

  return (
    <Stack
      width={{ xs: "100%", md: 300 }}
      height={{ xs: "auto", md: `calc(100vh - ${navHeight}px)` }}
      direction={{ xs: "row", md: "column" }}
      justifyContent={{ xs: "space-between", md: "flex-start" }}
      position="fixed"
      paddingTop={2}
      paddingLeft={{ xs: 2, md: 5 }}
      paddingRight={{ xs: 2, md: 2 }}
      paddingBottom={{ xs: 2, md: 5 }}
      bottom={0}
      zIndex={1}
      bgcolor={colors.white}
    >
      {tabs.map((tab) => (
        <Stack
          key={tab.value}
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          gap={{ xs: 0.2, md: 1 }}
          padding={{ xs: 0, md: 2 }}
          onClick={() => onTabChange(tab.value)}
          borderRadius={4}
          bgcolor={{ xs: "transparent", md: selectedTab === tab.value ? colors.grey05 : "transparent" }}
          sx={{ cursor: "pointer" }}
        >
          {selectedTab === tab.value ? <tab.filledIcon /> : <tab.icon color="action" />}
          <Typography fontSize={{ xs: 10, md: 16 }} color={selectedTab === tab.value ? colors.black : colors.grey60}>
            {tab.label}
          </Typography>
        </Stack>
      ))}
      {!isMobileScreen && (
        <Stack flex={1} alignItems="flex-start" justifyContent="flex-end">
          <Button tertiary size="medium" onClick={() => authSignOutUser(navigate)}>
            Logout
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default SideBar;
