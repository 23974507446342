import { Stack } from "@mui/material";
import { colors } from "../../theme";
import { Grid, GridItem } from "../Layout";
import { Text } from "../Text";
import TextLink from "../TextLink";

const Item = ({ children, noBorder, ...props }: any) => (
  <GridItem span={4} padding="8px 16px" outline={!noBorder} {...props}>
    <Text>{children}</Text>
  </GridItem>
);

const XmasLastDeliveryDates = () => {
  return (
    <Stack gap={2}>
      <div>
        <Text size={20}>Last order dates for Christmas</Text>
        <Text size={14} color={colors.grey40}>
          *These are the recommended dates from our partners and are not guaranteed
        </Text>
      </div>
      <Grid columns={12}>
        <Item noBorder />
        <Item backgroundColor={colors.grey02}>Print Only</Item>
        <Item backgroundColor={colors.grey02}>Framed Prints</Item>

        <Item backgroundColor={colors.grey02}>UK</Item>
        <Item align="center">17th</Item>
        <Item align="center">18th</Item>

        <Item backgroundColor={colors.grey02}>Netherlands & Belgium</Item>
        <Item align="center">17th</Item>
        <Item align="center">17th</Item>

        <Item backgroundColor={colors.grey02}>Germany</Item>
        <Item align="center">16th</Item>
        <Item align="center">16th</Item>

        <Item backgroundColor={colors.grey02}>Rest of Europe Mainland</Item>
        <Item align="center">13th</Item>
        <Item align="center">13th</Item>

        <Item backgroundColor={colors.grey02}>US</Item>
        <Item align="center">16th</Item>
        <Item align="center">16th</Item>

        <Item backgroundColor={colors.grey02}>Australia</Item>
        <Item align="center">11th</Item>
        <Item align="center">11th</Item>
      </Grid>
      <div>
        <Text color={colors.error}>- If ordering both print only and framed print please go off print only dates</Text>
        <Text>
          - If you're ordering for somewhere else, please contact us by emailing{" "}
          <TextLink href="mailto:hello@goodmoodprints.io<">hello@goodmoodprints.io</TextLink> to check dates
        </Text>
      </div>
    </Stack>
  );
};

export default XmasLastDeliveryDates;
