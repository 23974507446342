import { useContext, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToggle } from "react-use";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import { Medium, Frame } from "../../types/product";
import { Icon } from "../../components/Icon";
import ProductCard from "../../components/ProductCard";
import { FiltersButton, StickyContainer } from "./styles";
import { useAppState } from "../../state";
import { Loader } from "../../components/Loader";
import Button from "../../components/Button";
import { useShop } from "./useShop";
import FrameBadges from "../../components/FrameBadges";
import { ProductSortKeys } from "../../generated/storefront";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import FiltersBar from "./Filters/Bar";
import FiltersDrawer from "./Filters/Drawer";
import { updateProduct } from "../../services/API";
import Checkbox from "../../components/Checkbox";
import AuthContext from "../../state/auth";
import { isNextMonth } from "../../helpers/time";
import AutoResizeText from "../../components/AutoResizeText";
import ReadMoreText from "../../components/ReadMoreText";
import FeaturedCollections from "./FeaturedCollections";

export type Filters = {
  mediums: Medium[];
  sortKey: ProductSortKeys;
  collection: boolean;
  reverse?: boolean;
  campaign?: string;
};

export type SearchParams = {
  mediums?: string;
  sortKey?: ProductSortKeys;
  collection?: boolean;
  reverse?: boolean;
  campaign?: string;
};

const Shop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isAdmin } = useContext(AuthContext);
  const { handle } = useParams();
  const [searchParams] = useSearchParams();
  const { navHeight, isMobileScreen } = useAppState();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedFrame, setSelectedFrame] = useState(Frame.Natural);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const {
    products,
    loadMore,
    clearFilters,
    loading,
    hasMore,
    refetch,
    collectionTitle,
    collectionCategory,
    collectionDescription,
    collectionDescriptionHtml,
  } = useShop({
    handle,
  });
  const filters = ["month", "medium", "size", "style", "subject", "orientation"];
  let filterCount = 0;
  searchParams.forEach((value, key) => filters.includes(key) && value && filterCount++);
  useLoadMoreOnScroll(ref, loadMore, hasMore, loading);

  const saveScrollPosition = () => {
    window.sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const updateProducts = async (tags: string[]) => {
    const gmTags = tags.map((tag) => `gm.${tag}`);
    const promises = selectedProducts.map((productId) => {
      const existingGmTags =
        products?.find((product) => product.id === productId)?.tags.filter((tag) => tag.startsWith("gm")) || [];
      return updateProduct(productId, { gmTags: [...existingGmTags, ...gmTags] });
    });
    await Promise.allSettled(promises);
    alert("Tag added to products");
    refetch();
    setSelectedProducts([]);
  };

  const selectProduct = (productId: string) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  return (
    <>
      <Helmet>
        <title>{handle ? `Shop ${collectionTitle}` : "Shop"} | GoodMood</title>
        <meta
          name="description"
          content={collectionDescription || "Fill your space with work that inspires, entertains and resonates with you"}
        />
      </Helmet>
      <div>
        <Box padding={2} paddingBottom={4} position="relative">
          <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
            <AutoResizeText text={collectionTitle} align="center" maxSize={isMobileScreen ? 50 : 85} />
            {handle && collectionDescriptionHtml ? (
              <ReadMoreText width={{ xs: "100%", md: "50%" }} align="center" markup>
                {collectionDescriptionHtml}
              </ReadMoreText>
            ) : (
              <Typography width={{ xs: "100%", md: "50%" }} align="center">
                Fill your space with work that inspires, entertains and resonates with you
              </Typography>
            )}
          </Stack>
        </Box>

        {filterCount === 0 && collectionCategory && (
          <FeaturedCollections collectionCategory={collectionCategory} isMobileScreen={isMobileScreen} />
        )}

        <StickyContainer bgcolor={colors.white} padding={{ xs: "12px 24px", md: "12px 40px" }} navHeight={navHeight}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {isMobileScreen ? (
              <FiltersButton onClick={toggleShowFilters}>
                <Typography fontSize={14} margin="0 8px 0 0">
                  Filters {filterCount > 0 ? `(${filterCount})` : ""}
                </Typography>
                <Icon icon="filters" fill={colors.black} size={24} />
              </FiltersButton>
            ) : (
              <FiltersBar updateProducts={updateProducts} productsSelected={selectedProducts.length > 0} />
            )}
            <Stack direction="row" justifyContent="flex-end">
              <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} size="large" />
            </Stack>
          </Stack>
        </StickyContainer>

        {isMobileScreen && <FiltersDrawer isOpen={showFilters} onClose={toggleShowFilters} />}

        <Box
          ref={ref}
          padding={{ xs: "0 0 40px", md: "24px 40px 40px" }}
          minHeight={!products || loading ? `${window.sessionStorage.getItem("scrollPosition")}px` : "auto"}
        >
          {products && !loading ? (
            <>
              {products.length === 0 ? (
                <Box padding={{ xs: "0 24px", md: "0" }}>
                  <Stack gap={2} alignItems="center">
                    <Typography>No pieces with selected filters</Typography>
                    <Button size="medium" onClick={clearFilters}>
                      Clear filters
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <>
                  <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                    {products.map((product) => (
                      <Grid item xs={6} md={3} xl={3} xxl={2} xxxl={1.5} key={product.id} onClick={saveScrollPosition}>
                        <ProductCard
                          nextMonths={isNextMonth(product.productType)}
                          product={product}
                          indent={isMobileScreen}
                          frame={selectedFrame}
                        />
                        {isAdmin && (
                          <Checkbox
                            label="Select"
                            checked={selectedProducts.includes(product.id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              selectProduct(product.id);
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  {loading ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button onClick={loadMore}>Load more</Button>
                    </Stack>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </div>
    </>
  );
};

export default Shop;
