import { isEmpty } from "lodash";
import { CheckCircleRounded, CircleOutlined } from "@mui/icons-material";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import { PrintSetProductFragment } from "../../generated/storefront";
import { truncatedTextStyles } from "../../state/constants";

type Props = {
  category: {
    label: string;
    value: string;
  };
  selectedCategories: string[];
  imageryObject: { [key: string]: PrintSetProductFragment[] };
  onClick: (category: string) => void;
};

const CategoryCard = ({ category, selectedCategories, imageryObject, onClick }: Props) => {
  return (
    <Box
      key={category.value}
      maxWidth={{ xs: "none", md: 186 }}
      width={{ xs: "calc(50% - 8px)", md: "auto" }}
      border={`1px solid ${colors.grey10}`}
      borderRadius={4}
      overflow="hidden"
      onClick={() => onClick(category.value)}
      sx={{ cursor: "pointer" }}
    >
      <Stack direction="row" width="100%" gap={0.25}>
        {!isEmpty(imageryObject) && (
          <>
            <Box width="50%" height={{ xs: 142, md: 168 }}>
              <img
                key={imageryObject[category.value][0].id}
                src={imageryObject[category.value][0].images.nodes[0].src}
                alt={imageryObject[category.value][0].images.nodes[0].altText || imageryObject[category.value][0].title}
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Box>

            <Stack width="50%" gap={0.25}>
              {imageryObject[category.value].slice(1).map((product) => (
                <Box key={product.id} height={{ xs: 70, md: 83 }}>
                  <img
                    key={product.id}
                    src={product.images.nodes[0].src}
                    alt={product.images.nodes[0].altText || product.title}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
              ))}
            </Stack>
          </>
        )}
      </Stack>

      <Stack gap={1} direction="row" alignItems="center" padding={2}>
        <Checkbox
          checked={selectedCategories.includes(category.value)}
          icon={<CircleOutlined fontSize="small" />}
          checkedIcon={<CheckCircleRounded fontSize="small" />}
        />
        <Typography {...truncatedTextStyles}>{category.label}</Typography>
      </Stack>
    </Box>
  );
};
export default CategoryCard;
