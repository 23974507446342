import { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import AuthContext from "../../state/auth";
// import { HomeRounded, MenuBookRounded, PersonOutlineRounded } from "@mui/icons-material";
// import TextLink from "../../components/TextLink";
import Button from "../../components/Button";
import AutoResizeText from "../../components/AutoResizeText";
import { colors } from "../../theme";

const Account = () => {
  const { customer } = useContext(AuthContext);
  // const [selectedTab] = useState<"home" | "profile" | "addresses">("home");

  const logoutCustomer = () => {
    localStorage.removeItem("customerAccessToken");
    window.location.reload();
  };

  // const Tab = ({ children, onClick }: PropsWithChildren<{ onClick: () => void }>) => (
  //   <Typography
  //     variant="h5"
  //     display="flex"
  //     flexDirection="row"
  //     alignItems="center"
  //     gap={1}
  //     onClick={onClick}
  //     sx={{ cursor: "pointer" }}
  //   >
  //     {children}
  //   </Typography>
  // );

  if (!customer) return null;

  return (
    <>
      <Helmet>
        <title>Account | GoodMood</title>
      </Helmet>
      <Stack alignItems="center" gap={{ xs: 4, md: 6 }} padding={{ xs: 2, md: 4 }}>
        <Stack gap={1} alignItems="center" width="100%">
          {customer.firstName ? (
            <AutoResizeText text={`${customer.firstName} ${customer.lastName}`} align="center" />
          ) : (
            <AutoResizeText text="My Account" align="center" />
          )}
          <Typography align="center">{customer.email}</Typography>
        </Stack>
        <Box
          bgcolor={colors.grey02}
          padding={5}
          minWidth={{ xs: 0, md: 400 }}
          width={{ xs: "100%", md: "auto" }}
          borderRadius={4}
        >
          <Stack gap={2} alignItems="center">
            <Typography align="center" fontSize={{ xs: 16, md: 20 }}>
              More account information coming soon, in the meantime...
            </Typography>
            <Link to="/shop" style={{ width: "100%" }}>
              <Button fullWidth>Go to shop</Button>
            </Link>
            <Link to="/wishlist" style={{ width: "100%" }}>
              <Button secondary fullWidth>
                Go to my wishlists
              </Button>
            </Link>
          </Stack>
        </Box>

        <Button onClick={logoutCustomer} tertiary>
          <Typography variant="h5">Log out</Typography>
        </Button>
      </Stack>
    </>
  );

  // return (
  //   <Stack direction="row" gap={6} padding={4} paddingY={10} maxWidth={900} margin="auto">
  //     <Box width="30%">
  //       <Stack gap={2}>
  //         <Typography variant="h3">
  //           {customer.firstName} {customer.lastName}
  //         </Typography>
  //         <Tab onClick={() => setSelectedTab("home")}>
  //           <HomeRounded fontSize="inherit" />
  //           Home
  //         </Tab>
  //         <Tab onClick={() => setSelectedTab("profile")}>
  //           <PersonOutlineRounded fontSize="inherit" />
  //           Profile
  //         </Tab>
  //         <Tab onClick={() => setSelectedTab("addresses")}>
  //           <MenuBookRounded fontSize="inherit" />
  //           Address book
  //         </Tab>
  //         <TextLink onClick={logoutCustomer}>
  //           <Typography variant="h5">Log out</Typography>
  //         </TextLink>
  //       </Stack>
  //     </Box>
  //     <Box width="100%">
  //       {selectedTab === "home" && (
  //         <Box>
  //           <Stack gap={1}>
  //             <Typography variant="h2">
  //               {customer.firstName} {customer.lastName}
  //             </Typography>
  //             <Typography>{customer.email}</Typography>
  //             <Typography>{customer.phone}</Typography>
  //             <Link to="/wishlists">
  //               <Button>My wishlists</Button>
  //             </Link>
  //             <Button onClick={logoutCustomer} tertiary>
  //               <Typography variant="h5">Log out</Typography>
  //             </Button>
  //           </Stack>
  //         </Box>
  //       )}

  //       {selectedTab === "profile" && <Box></Box>}

  //       {selectedTab === "addresses" && (
  //         <Box>
  //           <h3>Address</h3>
  //           <Box>
  //             <p>Address: {customer.defaultAddress?.address1}</p>
  //           </Box>
  //           <Box>
  //             <p>City: {customer.defaultAddress?.city}</p>
  //           </Box>
  //           <Box>
  //             <p>Country: {customer.defaultAddress?.country}</p>
  //           </Box>
  //           <Box>
  //             <p>Zip: {customer.defaultAddress?.zip}</p>
  //           </Box>
  //         </Box>
  //       )}
  //     </Box>
  //   </Stack>
  // );
};

export default Account;
