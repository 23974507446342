import { format, startOfMonth, subMonths } from "date-fns";
import { Medium, MediumNames } from "../types/product";

export const deliveryCountries = [
  "Australia",
  "Austria",
  "Belgium",
  "Canada",
  "China",
  "Croatia",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Guernsey",
  "Hong Kong SAR",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Latvia",
  "Lebanon",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Oman",
  "Philippines",
  "Poland",
  "Portugal",
  "Saudi Arabia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tanzania",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Vatican City",
  "Vietnam",
];

export const freeDeliveryCountries = [
  {
    countryCode: "GB",
    name: "United Kingdom",
    minOrderValue: 200,
  },
  {
    countryCode: "IM",
    name: "Isle of Man",
    minOrderValue: 200,
  },
  {
    countryCode: "GG",
    name: "Guernsey",
    minOrderValue: 200,
  },
  {
    countryCode: "US",
    name: "United States",
    minOrderValue: 250,
  },
  {
    countryCode: "AT",
    name: "Austria",
    minOrderValue: 250,
  },
  {
    countryCode: "BE",
    name: "Belgium",
    minOrderValue: 250,
  },
  {
    countryCode: "HR",
    name: "Croatia",
    minOrderValue: 250,
  },
  {
    countryCode: "DK",
    name: "Denmark",
    minOrderValue: 250,
  },
  {
    countryCode: "EE",
    name: "Estonia",
    minOrderValue: 250,
  },
  {
    countryCode: "FI",
    name: "Finland",
    minOrderValue: 250,
  },
  {
    countryCode: "FR",
    name: "France",
    minOrderValue: 250,
  },
  {
    countryCode: "DE",
    name: "Germany",
    minOrderValue: 250,
  },
  {
    countryCode: "GR",
    name: "Greece",
    minOrderValue: 250,
  },
  {
    countryCode: "HU",
    name: "Hungary",
    minOrderValue: 250,
  },
  {
    countryCode: "IS",
    name: "Iceland",
    minOrderValue: 250,
  },
  {
    countryCode: "IT",
    name: "Italy",
    minOrderValue: 250,
  },
  {
    countryCode: "LV",
    name: "Latvia",
    minOrderValue: 250,
  },
  {
    countryCode: "LT",
    name: "Lithuania",
    minOrderValue: 250,
  },
  {
    countryCode: "LU",
    name: "Luxembourg",
    minOrderValue: 250,
  },
  {
    countryCode: "NL",
    name: "Netherlands",
    minOrderValue: 250,
  },
  {
    countryCode: "PL",
    name: "Poland",
    minOrderValue: 250,
  },
  {
    countryCode: "PT",
    name: "Portugal",
    minOrderValue: 250,
  },
  {
    countryCode: "IE",
    name: "Republic of Ireland",
    minOrderValue: 250,
  },
  {
    countryCode: "SK",
    name: "Slovakia",
    minOrderValue: 250,
  },
  {
    countryCode: "SI",
    name: "Slovenia",
    minOrderValue: 250,
  },
  {
    countryCode: "ES",
    name: "Spain",
    minOrderValue: 250,
  },
  {
    countryCode: "SE",
    name: "Sweden",
    minOrderValue: 250,
  },
  {
    countryCode: "CH",
    name: "Switzerland",
    minOrderValue: 250,
  },
];

const subMonthsFromToday = (months: number) => {
  const monthStart = startOfMonth(new Date());
  return {
    label: format(subMonths(monthStart, months), "MMMM yyyy"),
    value: format(subMonths(monthStart, months), "yyyy-MM"),
  };
};

export const last12Months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((monthsToSubtract) =>
  subMonthsFromToday(monthsToSubtract)
);

export const giftCardId = "gid://shopify/Product/7925959131350";

const mediums = Object.values(Medium);
export const mediumOptions = mediums.map((c) => ({ value: c, label: MediumNames[c] }));

export const orientationOptions = [
  {
    label: "Portrait",
    value: "portrait",
  },
  {
    label: "Landscape",
    value: "landscape",
  },
  {
    label: "Square",
    value: "square",
  },
];

export const styleFilterOptions = [
  { label: "Abstract", value: "abstract", collectionHandle: "abstract" },
  { label: "Black & White", value: "blackandwhite", collectionHandle: "black-white-2" },
  { label: "Bold", value: "bold", collectionHandle: "bold" },
  { label: "Brutalist", value: "brutalist", collectionHandle: "brutalist" },
  { label: "Collage", value: "collage", collectionHandle: "collage" },
  { label: "Cottagecore", value: "cottagecore", collectionHandle: "cottagecore" },
  { label: "Digital Painting", value: "digitalpainting", collectionHandle: "digital-painting" },
  { label: "Electric", value: "electric", collectionHandle: "electric" },
  { label: "Escapist", value: "escapist", collectionHandle: "escapist" },
  { label: "Geometric", value: "geometric", collectionHandle: "geometric" },
  { label: "Illustrative", value: "illustrative", collectionHandle: "illustrative" },
  { label: "Line Art", value: "lineart", collectionHandle: "line-art" },
  { label: "Minimalist", value: "minimalist", collectionHandle: "minimalist" },
  { label: "Monochromatic", value: "monochromatic", collectionHandle: "monochromatic" },
  { label: "Oriental", value: "oriental", collectionHandle: "oriental" },
  { label: "Photography", value: "photography", collectionHandle: "photography" },
  { label: "Playful", value: "playful", collectionHandle: "playful" },
  { label: "Pop Art", value: "popart", collectionHandle: "pop-art" },
  { label: "Scandi", value: "scandi", collectionHandle: "scandi" },
  { label: "Soft Colour", value: "softcolour", collectionHandle: "soft-colour" },
  { label: "Surrealism", value: "surrealism", collectionHandle: "surrealism" },
  { label: "Swiss Design", value: "swissdesign", collectionHandle: "swiss-design" },
  { label: "Typographic", value: "typographic", collectionHandle: "typographic" },
  { label: "Whimsical", value: "whimsical", collectionHandle: "whimsical" },
];

export const subjectFilterOptions = [
  {
    label: "Animals",
    value: "animals",
    collectionHandle: "animals",
  },
  {
    label: "Architecture",
    value: "architecture",
    collectionHandle: "architecture",
  },
  {
    label: "Beach",
    value: "beach",
    collectionHandle: "beach",
  },
  {
    label: "Botanical",
    value: "botanical",
    collectionHandle: "botanical",
  },
  {
    label: "Floral",
    value: "floral",
    collectionHandle: "floral",
  },
  {
    label: "Food & Drink",
    value: "foodanddrink",
    collectionHandle: "food-drink",
  },
  {
    label: "Landscapes",
    value: "landscapes",
    collectionHandle: "landscapes",
  },
  {
    label: "Matchboxes",
    value: "matchboxes",
    collectionHandle: "matchboxes",
  },
  {
    label: "Music",
    value: "music",
    collectionHandle: "music",
  },
  {
    label: "Nude",
    value: "nude",
    collectionHandle: "nude",
  },
  {
    label: "People",
    value: "people",
    collectionHandle: "people",
  },
  {
    label: "Sports",
    value: "sports",
    collectionHandle: "sports",
  },
  {
    label: "Still Life",
    value: "stilllife",
    collectionHandle: "still-life",
  },
  {
    label: "Travel",
    value: "travel",
    collectionHandle: "travel",
  },
  {
    label: "Urban",
    value: "urban",
    collectionHandle: "urban",
  },
];

export const allFilterOptions = [...styleFilterOptions, ...subjectFilterOptions];

export const scrollbarStyles = {
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

export const truncatedTextStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const truncateThreeLinesStyles = {
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": "3",
  "-webkit-box-orient": "vertical",
};
