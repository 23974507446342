import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { subMonths } from "date-fns";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import allImage from "../../../assets/images/all.webp";
import giftGuideImage from "../../../assets/images/giftguide.webp";
import printSetsImage from "../../../assets/images/print-sets.webp";
import { getMonthsDate, getThisMonthsDate } from "../../../helpers/time";
import { NavItem } from "../styles";
import { colors } from "../../../theme";
import { scrollbarStyles } from "../../../state/constants";
import { CountryCode, ProductSortKeys, useGetProductsForProductCardQuery } from "../../../generated/storefront";

const ShopMenu = ({ onCloseNav }: { onCloseNav: () => void }) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const { data: trendingData } = useGetProductsForProductCardQuery({
    variables: {
      query: `product_type:${getThisMonthsDate()} OR product_type:${getMonthsDate(
        subMonths(new Date(), 1)
      )} OR product_type:${getMonthsDate(subMonths(new Date(), 2))} OR product_type:${getMonthsDate(subMonths(new Date(), 3))}`,
      sortKey: ProductSortKeys.BestSelling,
      country: CountryCode.Gb,
      limit: 1,
    },
  });
  const { data: ourPicksData } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: `tag:pick product_type:${getThisMonthsDate()}`,
      country: CountryCode.Gb,
      limit: 1,
    },
  });
  const trendingProducts = useMemo(() => trendingData?.products.nodes || [], [trendingData]);
  const ourPicksProducts = useMemo(() => ourPicksData?.products.nodes || [], [ourPicksData]);
  const trendingImage = useMemo(
    () => (trendingProducts.length ? trendingProducts[0].images.nodes[0].src + "&width=200" : ""),
    [trendingProducts]
  );
  const ourPicksImage = useMemo(
    () => (ourPicksProducts.length ? ourPicksProducts[0].images.nodes[0].src + "&width=200" : ""),
    [ourPicksProducts]
  );

  return (
    <Box bgcolor={menuOpen ? colors.grey02 : colors.white}>
      <NavItem fontSize={18} onClick={() => setMenuOpen(!menuOpen)} paddingY={1.5} paddingX={2}>
        Shop {menuOpen ? <ExpandLessRounded fontSize="small" /> : <ExpandMoreRounded fontSize="small" />}
      </NavItem>
      {menuOpen && (
        <Stack direction="row" gap={1} paddingBottom={1.5} paddingX={2} overflow="auto" sx={scrollbarStyles}>
          <Link to="/shop" onClick={onCloseNav}>
            <Stack gap={1}>
              <img
                src={allImage}
                alt="Shop"
                width={70}
                height={70}
                style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
              />
              <Typography fontSize={12} align="center">
                All
              </Typography>
            </Stack>
          </Link>
          <Link to="/print-sets" onClick={onCloseNav}>
            <Stack gap={1}>
              <img
                src={printSetsImage}
                alt="Print sets"
                width={70}
                height={70}
                style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
              />
              <Typography fontSize={12} align="center">
                Print Sets
              </Typography>
            </Stack>
          </Link>
          <Link to="/gift-guide" onClick={onCloseNav}>
            <Stack gap={1}>
              <img
                src={giftGuideImage}
                alt="Gift guide"
                width={70}
                height={70}
                style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
              />
              <Typography fontSize={12} align="center">
                Gift Guide
              </Typography>
            </Stack>
          </Link>
          <Link
            to={`/shop?month=${getThisMonthsDate()},${getMonthsDate(subMonths(new Date(), 1))},${getMonthsDate(
              subMonths(new Date(), 2)
            )},${getMonthsDate(subMonths(new Date(), 3))}`}
            onClick={onCloseNav}
          >
            <Stack gap={1}>
              {trendingImage ? (
                <img
                  src={trendingImage}
                  alt="Trending"
                  width={70}
                  height={70}
                  style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
                />
              ) : (
                <Skeleton width={70} height={70} style={{ borderRadius: 4, transform: "none" }} />
              )}
              <Typography fontSize={12} align="center">
                Trending
              </Typography>
            </Stack>
          </Link>
          <Link to={"/shop?month=" + getThisMonthsDate()} onClick={onCloseNav}>
            <Stack gap={1}>
              {ourPicksImage ? (
                <img
                  src={ourPicksImage}
                  alt="New"
                  width={70}
                  height={70}
                  style={{ borderRadius: 4, border: `1px solid ${colors.white}`, objectFit: "cover" }}
                />
              ) : (
                <Skeleton width={70} height={70} style={{ borderRadius: 4, transform: "none" }} />
              )}
              <Typography fontSize={12} align="center">
                New
              </Typography>
            </Stack>
          </Link>
        </Stack>
      )}
    </Box>
  );
};

export default ShopMenu;
