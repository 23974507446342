import { Box, Stack, Typography } from "@mui/material";
import ShareIcons from "../../components/ShareIcons";
import { scrollbarStyles } from "../../state/constants";
import { colors } from "../../theme";

type Props = {
  text: string;
  pageUrl: string;
  setShareBarOpen: (value: boolean) => void;
  isMobileScreen: boolean;
};

const ShareBar = ({ text, pageUrl, setShareBarOpen, isMobileScreen }: Props) => {
  return (
    <Box
      position="fixed"
      height="100vh"
      bottom={0}
      left={0}
      right={0}
      zIndex={100}
      bgcolor="rgba(0,0,0,0.4)"
      onClick={() => setShareBarOpen(false)}
    >
      <Stack
        onClick={(e) => e.stopPropagation()}
        bgcolor={colors.white}
        paddingY={3}
        borderRadius="32px 32px 0 0"
        position="absolute"
        bottom={0}
        width="100%"
        alignItems="flex-start"
        gap={2}
      >
        <Typography paddingX={2}>Share to</Typography>
        <Stack direction="row" overflow="auto" width="100%" paddingX={2} sx={scrollbarStyles}>
          <ShareIcons text={text} pageUrl={pageUrl} size={56} isMobileScreen={isMobileScreen} showLabels />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ShareBar;
