import { useRef } from "react";
import { match } from "ts-pattern";
import styled from "styled-components";
import html2canvas from "html2canvas";
import { Box, Grid, Stack } from "@mui/material";
import { Frame, Ratio } from "../../../types/product";
import portraitMockUp from "../../../assets/images/mock-ups/Portrait.png";
import landscapeMockUp from "../../../assets/images/mock-ups/Landscape.png";
import squareMockUp from "../../../assets/images/mock-ups/Square.png";
import plainAssetImage from "./plain-asset.webp";
import wallAssetImage from "./wall-asset.webp";
import mockupAssetImage from "./mockup-asset.webp";
import Button from "../../../components/Button";
import { trackEvent } from "../../../helpers/analytics";
import { getRatioFromVariants } from "../../../helpers/product";
import { ArtistProductFragment } from "../../../generated/graphql";
import FrameMockUp from "../../../components/FrameMockUp";

export const MockUpBackground = styled.img`
  position: relative;
  width: 100%;
`;

const MockUpImage = styled.img<{ positions: { top: string; left: string; width: string } }>`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  ${({ positions }) => `
    top: ${positions.top};
    left: ${positions.left};
    width: ${positions.width};
  `}
`;

const DownloadItem = ({ product }: { product: ArtistProductFragment }) => {
  const plainRef = useRef<HTMLDivElement>(null);
  const layeredRef = useRef<HTMLDivElement>(null);
  const wallRef = useRef<HTMLDivElement>(null);
  const mockupRef = useRef<HTMLDivElement>(null);
  const christmasRef = useRef<HTMLDivElement>(null);
  const image = product.images.nodes[0];
  const isLandscape = image.height < image.width;
  const ratio = getRatioFromVariants(product.variants.nodes);

  const downloadPlainAsset = () => {
    const element = plainRef.current as unknown as HTMLElement;
    downloadImage(element, "Story");
  };

  const downloadLayeredAsset = () => {
    const element = layeredRef.current as unknown as HTMLElement;
    downloadImage(element, "Layered Story");
  };

  const downloadWallAsset = () => {
    const element = wallRef.current as unknown as HTMLElement;
    downloadImage(element, "Wall Story");
  };

  const downloadMockupAsset = () => {
    const element = mockupRef.current as unknown as HTMLElement;
    downloadImage(element, "Mockup Story");
  };

  const downloadChristmasAsset = () => {
    const element = christmasRef.current as unknown as HTMLElement;
    downloadImage(element, "Chistmas Story");
  };

  const downloadImage = async (element: any, type: string) => {
    trackEvent("DownloadAsset");
    const canvas = await html2canvas(element, {
      useCORS: true,
      scale: 10,
    });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = `${product.title} ${type}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const christmasAssetImage = match(ratio)
    .with(Ratio.Square, () => squareMockUp)
    .with(Ratio.Rectangle, () => (isLandscape ? landscapeMockUp : portraitMockUp))
    .exhaustive();

  const christmasAssetPositions = match(ratio)
    .with(Ratio.Square, () => ({ top: "31.7%", left: "51%", width: "42.5%" }))
    .with(Ratio.Rectangle, () =>
      isLandscape ? { top: "30.8%", left: "52.6%", width: "54%" } : { top: "31.8%", left: "52.6%", width: "42.2%" }
    )
    .exhaustive();

  const mockupAssetPositions = { top: "41.5%", left: "51.5%", width: "57%" };

  return (
    <>
      <Grid item xs={6} md={12 / 5}>
        <Stack gap={1.5} alignItems="center">
          <Stack justifyContent="center" ref={plainRef} style={{ position: "relative" }}>
            <MockUpBackground src={plainAssetImage} alt="plain background" />
            <Box width="80%" position="absolute" top="50%" left="50%" sx={{ transform: "translate(-50%, -50%)" }}>
              <FrameMockUp image={image} frame={Frame.Black} shadow />
            </Box>
          </Stack>

          <Button onClick={downloadPlainAsset} secondary size="medium">
            Download
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={6} md={12 / 5}>
        <Stack gap={1.5} alignItems="center" height="100%">
          <Stack justifyContent="center" ref={layeredRef} position="relative" flex={1}>
            <img src={image.src} alt="piece" width="100%" height="100%" style={{ objectFit: "cover" }} />
            <Box width="80%" position="absolute" top="50%" left="50%" sx={{ transform: "translate(-50%, -50%)" }}>
              <FrameMockUp image={image} frame={Frame.Black} />
            </Box>
          </Stack>

          <Button onClick={downloadLayeredAsset} secondary size="medium">
            Download
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={6} md={12 / 5}>
        <Stack gap={1.5} alignItems="center">
          <Stack justifyContent="center" ref={wallRef} position="relative">
            <MockUpBackground src={wallAssetImage} alt="wall background" />
            <Box width="60%" position="absolute" top="20%" left="50%" sx={{ transform: "translateX(-50%)" }}>
              <FrameMockUp image={image} frame={Frame.Black} shadow />
            </Box>
          </Stack>

          <Button onClick={downloadWallAsset} secondary size="medium">
            Download
          </Button>
        </Stack>
      </Grid>

      {ratio === Ratio.Rectangle && !isLandscape && (
        <Grid item xs={6} md={12 / 5}>
          <Stack gap={1.5} alignItems="center">
            <Stack justifyContent="center" ref={mockupRef} position="relative">
              <MockUpBackground src={mockupAssetImage} alt="mock up" />
              <MockUpImage
                src={image.src}
                height="auto"
                width="auto"
                alt="print"
                positions={mockupAssetPositions}
                style={{ zIndex: -1 }}
              />
            </Stack>

            <Button onClick={downloadMockupAsset} secondary size="medium">
              Download
            </Button>
          </Stack>
        </Grid>
      )}

      <Grid item xs={6} md={12 / 5}>
        <Stack gap={1.5} alignItems="center">
          <Stack justifyContent="center" ref={christmasRef} position="relative">
            <MockUpBackground src={christmasAssetImage} alt="christmas background" />
            <MockUpImage src={image.src} height="auto" width="auto" alt="print" positions={christmasAssetPositions} />
          </Stack>

          <Button onClick={downloadChristmasAsset} secondary size="medium">
            Download
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default DownloadItem;
