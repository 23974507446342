import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { capitalize } from "lodash";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { CloseRounded, InfoOutlined } from "@mui/icons-material";
import { Frame, Size } from "../../types/product";
import { Container } from "../../components/Layout";
import { formattedPrice } from "../../helpers/money";
import Button from "../../components/Button";
import { User } from "../../types/user";
import RadioButton from "../../components/Radio/button";
import { useAppState } from "../../state";
import { isNextMonth } from "../../helpers/time";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";
import AuthContext from "../../state/auth";
import { getAvailableSizes, getSizeAndFrame, sizeLabelsForCountry } from "../../helpers/product";
import InfoAccordion from "./InfoAccordion";
import { scrollbarStyles } from "../../state/constants";
import { CountryCode, ProductPageProductFragment, useGetProductByHandleQuery } from "../../generated/storefront";
import Cards from "./Cards";
import useBasketActions from "../../hooks/useBasketActions";
import FrameBadges from "../../components/FrameBadges";
import WishlistBookmark from "../../components/WishlistBookmark";
import mounting from "../../assets/images/mount.png";
import { BadgeButton } from "../Shop/styles";
import whiteFrameBadge from "../../assets/images/whiteFrameBadge.svg";
import unframedBadge from "../../assets/images/unframedBadge.svg";
import { getFutureDiscountPct } from "../../helpers/basket";
import { getIdNumber } from "../../helpers/shopify";

export type CheckoutState = {
  quantity: number;
  size?: Size;
  frame?: Frame;
  mounted: boolean;
  denominations?: string;
} & { [key: string]: string | number | boolean };

type Props = {
  product: ProductPageProductFragment;
  artist?: User | null;
  state: CheckoutState;
  setState: (state: CheckoutState) => void;
  setEdited: (edited: boolean) => void;
};

const StickySection = styled(Container)<{ sticky: boolean; navHeight: number }>`
  position: ${(p) => (p.sticky ? "sticky" : "relative")};
  top: ${(p) => (p.sticky ? `${p.navHeight}px` : "0")};
  z-index: 1;
  background: ${colors.white};

  @media ${media.m} {
    position: relative;
    top: 0;
  }
`;

const SideBar = ({ product, artist, state, setState, setEdited }: Props) => {
  const { isAdmin, customer, refetchCustomer } = useContext(AuthContext);
  const { basket, selectedCountry, navHeight, isMobileScreen } = useAppState();
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const [stickyButtonVisible, setStickyButtonVisible] = useState(false);
  const [stickyButtonBottomPadding, setStickyButtonBottomPadding] = useState(16);
  const [searchParams, setSearchParams] = useSearchParams();
  const variantId = searchParams.get("variant");
  const mount = searchParams.get("mount");
  const variants = product.variants.nodes;
  const { addOrCreateBasket, loading, error } = useBasketActions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const popoverOpen = Boolean(anchorEl);
  const mountLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "Matt")
    .otherwise(() => "Mount");
  const mountingLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "Matting")
    .otherwise(() => "Mounting");
  const selectedVariant = useMemo(
    () =>
      variants.find((variant) =>
        variant.selectedOptions.every((option) => {
          return state[option.name] === option.value;
        })
      ),
    [variants, state]
  );
  const smallestSize = getAvailableSizes(product.variants.nodes)[0];
  const defaultVariant = variants.find((v) => {
    const { size, frame } = getSizeAndFrame(v.selectedOptions);
    return size === smallestSize && frame === Frame.Unframed;
  });
  const { data: mountingProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "mounting", country: selectedCountry },
  });
  const mountingProduct = useMemo(() => mountingProductData?.product, [mountingProductData]);
  const mountingVariant = mountingProduct?.variants.nodes.find((variant) => variant.selectedOptions[0].value === state.size);
  const mountingPrice = state.mounted ? Number(mountingVariant?.price.amount || 0) : 0;

  const addToBasket = () => {
    const mountedLineItem =
      state.mounted && mountingVariant ? [{ merchandiseId: mountingVariant.id, quantity: state.quantity }] : [];
    if (selectedVariant) {
      const lines = [
        {
          merchandiseId: selectedVariant.id,
          quantity: state.quantity,
          attributes: state.mounted
            ? [
                {
                  key: "mounted",
                  value: "yes",
                },
              ]
            : [],
        },
        ...mountedLineItem,
      ];
      addOrCreateBasket(lines);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setEdited(true);
  };

  const handleFrameChange = (frame: Frame) => {
    if (frame === Frame.Unframed) {
      setState({ ...state, frame, mounted: false });
    } else {
      setState({ ...state, frame });
    }
  };

  const handleMountingChange = (mounted: boolean) => {
    if (mounted && state.frame === Frame.Unframed) {
      setState({ ...state, mounted, frame: Frame.Natural });
    } else {
      setState({ ...state, mounted });
    }
  };

  useEffect(() => {
    if (variantId) {
      const paramVariant = variants.find((v) => getIdNumber(v.id, "ProductVariant") === variantId)?.selectedOptions;
      if (!paramVariant) return;
      const { size, frame } = getSizeAndFrame(paramVariant);
      const mounted = mount === "yes";
      setState({ ...state, size, frame, mounted });
    } else {
      setState({ ...state, size: smallestSize, frame: Frame.Unframed });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  useEffect(() => {
    const selectedVariant = variants.find((variant) =>
      variant.selectedOptions.every((option) => {
        return state[option.name] === option.value;
      })
    );
    if (!selectedVariant || product.isGiftCard) return;

    if (selectedVariant.id === defaultVariant?.id) {
      if (searchParams.size > 0) {
        searchParams.delete("variant");
        searchParams.delete("mount");
        setSearchParams(searchParams, { preventScrollReset: true });
      }
    } else {
      setSearchParams(
        { variant: getIdNumber(selectedVariant.id, "ProductVariant"), mount: state.mounted ? "yes" : "no" },
        { preventScrollReset: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id, state.size, state.frame, state.mounted]);

  const setStickyButtonVisibility = () => {
    const scrollThreshold = 600;
    if (isMobileScreen) {
      setStickyButtonVisible(window.scrollY > navHeight + scrollThreshold);
    } else {
      setStickyButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setStickyButtonVisibility);

    return () => {
      window.removeEventListener("scroll", setStickyButtonVisibility);
    };
  });

  let lastHeight = window.innerHeight;

  const adjustStickyButtonPadding = () => {
    const newHeight = window.innerHeight;
    if (newHeight < lastHeight) {
      setStickyButtonBottomPadding(16);
    } else if (newHeight > lastHeight) {
      setStickyButtonBottomPadding(32);
    }
    lastHeight = newHeight;
  };

  useEffect(() => {
    window.addEventListener("resize", adjustStickyButtonPadding);

    return () => {
      window.removeEventListener("resize", adjustStickyButtonPadding);
    };
  });

  const discountPct = getFutureDiscountPct(basket);
  const showDiscountedPrices = discountPct > 0;
  const currencyCode = product.variants.nodes[0].price.currencyCode;
  const selectedVariantPrice = Number(selectedVariant?.price.amount) + mountingPrice;

  if (product.isGiftCard) {
    return (
      <Stack width={{ xs: "100%", md: "33%" }}>
        <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack gap={2} height="100%">
              <Stack gap={1}>
                <Typography fontSize={{ xs: 12, md: 16 }}>Amount</Typography>
                <Stack onChange={handleChange} direction="row" overflow="auto" gap={1} sx={scrollbarStyles}>
                  {variants.map((v) => {
                    const denomationValue = formattedPrice(v.price.amount, currencyCode);
                    return (
                      <RadioButton
                        name="denominations"
                        key={denomationValue}
                        label={denomationValue}
                        id={v.price.amount}
                        checked={state.denominations === v.selectedOptions[0].value}
                        value={v.selectedOptions[0].value}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Stack paddingBottom={3} gap={1.5}>
                <Button fullWidth onClick={addToBasket} disabled={!state.denominations} loading={loading} size="large">
                  Add to basket
                </Button>
                {error && <Typography color={colors.error}>{error}</Typography>}
              </Stack>
            </Stack>
          </Box>
        </StickySection>
      </Stack>
    );
  }

  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const availabileSizes = getAvailableSizes(product.variants.nodes);

  if (!selectedVariant) return null;

  return (
    <Stack width={{ xs: "100%", md: "33%" }}>
      <Stack gap={2} height="100%">
        {artist && (
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack justifyContent="space-between" direction="row" gap={3}>
              <Stack gap={0.5}>
                {isMobileScreen ? (
                  <Typography component="h1">{product.title}</Typography>
                ) : (
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography variant="h2" component="h1">
                      {product.title}
                    </Typography>
                  </Stack>
                )}

                <Link to={`/artists/${artist.permalink || artist.id}`}>
                  <Typography fontSize={{ xs: 14, md: 16 }} style={{ textDecoration: "underline" }}>
                    {artist.firstName} {artist.lastName}
                  </Typography>
                </Link>
              </Stack>
              {isMobileScreen && (
                <Stack gap={0.5} alignItems="end">
                  <Stack gap={1.5} direction="row">
                    <Typography
                      style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                      color={showDiscountedPrices ? "text.disabled" : "primary"}
                      align="right"
                    >
                      {formattedPrice(selectedVariantPrice, currencyCode)}
                    </Typography>
                    {showDiscountedPrices && (
                      <Typography color={colors.red} align="right" style={{ whiteSpace: "nowrap" }}>
                        {formattedPrice(Number(selectedVariantPrice) * (1 - discountPct), currencyCode)}
                      </Typography>
                    )}
                  </Stack>
                  <WishlistBookmark
                    productId={product.id}
                    customer={customer}
                    refetchCustomer={refetchCustomer}
                    popoverOpen={popoverOpen}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        {isNextMonth(product.productType) && !isAdmin ? (
          <Box paddingX={{ xs: 2, md: 3 }}>
            <Typography>This product is coming in next month's collection</Typography>
          </Box>
        ) : (
          <>
            <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
              <Box paddingX={{ xs: 2, md: 3 }}>
                <Stack gap={2} height="100%">
                  <Stack gap={5} direction="row">
                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }}>Frame: {capitalize(state.frame)}</Typography>
                      <FrameBadges
                        selectedFrame={state.frame || Frame.Natural}
                        setSelectedFrame={handleFrameChange}
                        size="large"
                      />
                    </Stack>

                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }} display="flex" alignItems="center">
                        {mountLabel}: {state.mounted ? "Yes" : "No"}
                        <InfoOutlined
                          onClick={() => setDialogOpen(true)}
                          fontSize="inherit"
                          style={{ cursor: "pointer", marginLeft: 4, color: colors.grey40 }}
                        />
                      </Typography>
                      <Stack direction="row" gap={1.25}>
                        <BadgeButton selected={!state.mounted} onClick={() => handleMountingChange(false)} size="large">
                          <img src={unframedBadge} alt="unframed icon badge" width={32} height={32} />
                        </BadgeButton>
                        <BadgeButton selected={state.mounted} onClick={() => handleMountingChange(true)} size="large">
                          <img src={whiteFrameBadge} alt="black frame icon badge" width={32} height={32} />
                        </BadgeButton>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack gap={3}>
                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }}>
                        Size:{" "}
                        {state.size
                          ? sizeLabels[state.size][
                              state.frame === Frame.Unframed ? ("printMeasurements" as const) : ("framedMeasurements" as const)
                            ]
                          : ""}
                      </Typography>
                      <Stack onChange={handleChange} direction="row" gap={1}>
                        {availabileSizes.map((size) => (
                          <RadioButton
                            name="size"
                            key={size}
                            label={sizeLabels[size].shortHand}
                            id={size}
                            checked={state.size === size}
                            value={size}
                          />
                        ))}
                      </Stack>
                    </Stack>

                    {!isMobileScreen && (
                      <Stack gap={1.5} direction="row" alignItems="center">
                        <Typography
                          color={showDiscountedPrices ? "text.disabled" : "primary"}
                          style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                        >
                          {formattedPrice(selectedVariantPrice, currencyCode)}
                        </Typography>
                        {showDiscountedPrices && (
                          <Typography color={colors.red}>
                            {formattedPrice(Number(selectedVariantPrice) * (1 - discountPct), currencyCode)} · {discountPct * 100}
                            % OFF
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                  <Stack paddingBottom={1} gap={1.5}>
                    <Button
                      fullWidth
                      onClick={addToBasket}
                      disabled={!(state.size && state.frame && state.quantity)}
                      loading={loading}
                      size="large"
                    >
                      Add to basket
                    </Button>
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </StickySection>

            <div>
              <Cards />
              <InfoAccordion product={product} artist={artist} setItemIsOpen={setItemIsOpen} />
            </div>

            {stickyButtonVisible && (
              <Box
                position="fixed"
                bottom={0}
                bgcolor={colors.white}
                padding={2}
                paddingBottom={`${stickyButtonBottomPadding}px`}
                width="100%"
                zIndex={10}
                sx={{ transition: "padding-bottom 0.2s" }}
              >
                <Button
                  fullWidth
                  onClick={addToBasket}
                  disabled={!(state.size && state.frame && state.quantity)}
                  loading={loading}
                  size="large"
                >
                  {`Add to basket · ${formattedPrice(selectedVariantPrice, currencyCode)}`}
                </Button>
              </Box>
            )}
          </>
        )}

        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
          <CloseRounded
            onClick={() => setDialogOpen(false)}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 0,
              margin: 2,
              background: colors.white,
              borderRadius: "100%",
              padding: "4px",
            }}
          />
          <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
            <img src={mounting} alt="mounting" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </Box>
          <DialogContent>
            <Stack gap={1}>
              <Typography>{mountingLabel}</Typography>
              <Typography fontSize={12}>
                Enhance your framed piece with a 2 inch {mountLabel.toLowerCase()} around your print. The card is a
                conservation-grade, 2.4mm thick {mountLabel.toLowerCase()} board with bevelled edges. Note that the{" "}
                {mountLabel.toLowerCase()} will reduce the size of the print by 2 inches on each side.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default SideBar;
