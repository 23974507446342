import { Link } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import Button from "../../Button";
import { Loader } from "../../Loader";
import SearchProductCard from "./SearchProductCard";
import ArtistCard from "../../Card/Artist";
import { User } from "../../../types/user";
import { scrollbarStyles } from "../../../state/constants";
import { SearchProductFragment } from "../../../generated/storefront";

type Props = {
  artists: User[];
  products: SearchProductFragment[];
  searchQs: string;
  loadingArtists: boolean;
  loadingProducts: boolean;
  isMobile: boolean;
};

const SearchResults = ({ artists, products, searchQs, loadingArtists, loadingProducts, isMobile }: Props) => {
  return (
    <Stack gap={3} paddingBottom={4} width="100%" height="100%" sx={{ overflowY: "auto" }}>
      {loadingArtists ? (
        <Loader />
      ) : (
        artists.length > 0 && (
          <Stack gap={1} width="100%">
            <Typography variant="h4">Artists</Typography>

            {isMobile ? (
              <Stack gap={1} direction="row" overflow="auto" sx={scrollbarStyles}>
                {artists.map((artist) => (
                  <Stack flex="0 0 35%" width="35%">
                    <ArtistCard artist={artist} size="small" imageHeight="30vw" borderRadius={12} />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Grid container spacing={1}>
                {artists.map((artist) => (
                  <Grid item xs={12 / 4} key={artist.id}>
                    <ArtistCard artist={artist} size="small" imageHeight="150px" borderRadius={12} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Stack>
        )
      )}
      {loadingProducts ? (
        <Loader />
      ) : (
        products.length > 0 && (
          <Stack gap={1} width="100%">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">Artwork</Typography>
              <Link to={`/search?qs=${searchQs}`}>
                <Button tertiary size="small">
                  <Stack direction="row" gap={0.5} alignItems="center">
                    View all <ArrowForwardIosRounded fontSize="inherit" />
                  </Stack>
                </Button>
              </Link>
            </Stack>

            {isMobile ? (
              <Stack gap={1} direction="row" overflow="auto" sx={scrollbarStyles}>
                {products.map((product) => (
                  <Stack flex="0 0 35%" width="35%">
                    <SearchProductCard product={product} />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Grid container spacing={1}>
                {products.map((product) => (
                  <Grid item xs={12 / 6} key={product.id}>
                    <SearchProductCard product={product} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};

export default SearchResults;
