import { useRef } from "react";
import { sortBy } from "lodash";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Button from "../../../components/Button";
import AssetGroup from "./AssetGroup";
import { User } from "../../../types/user";
import { useGetArtistProductsQuery } from "../../../generated/graphql";

type Props = {
  user: User;
  isMobileScreen: boolean;
  toggleGalleryWallBuilderIsOpen: () => void;
};

const Assets = ({ user, isMobileScreen, toggleGalleryWallBuilderIsOpen }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { data, loading } = useGetArtistProductsQuery({
    variables: {
      query: `vendor:${user.id} status:active`,
      limit: 250,
    },
  });
  const products = sortBy(data?.products?.nodes, (product) => product.artistPosition?.value);

  return (
    <Box ref={ref} paddingRight={{ xs: 2, md: 5 }} paddingLeft={{ xs: 2, md: 0 }} paddingBottom={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" paddingY={2}>
        <Typography variant="h2">Assets</Typography>
        {!isMobileScreen && (
          <Button size="medium" onClick={toggleGalleryWallBuilderIsOpen} style={{ whiteSpace: "nowrap" }}>
            Gallery wall builder
          </Button>
        )}
      </Stack>
      <Stack gap={4}>
        {products?.length === 0 && (
          <Box padding={4} textAlign="center">
            <Typography variant="h4">No live pieces</Typography>
          </Box>
        )}
        {products?.map((product) => (
          <Grid container spacing={1.5} rowGap={3}>
            <AssetGroup product={product} key={product.id} />
          </Grid>
        ))}
      </Stack>

      {loading && <Loader />}
    </Box>
  );
};

export default Assets;
