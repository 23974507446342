import { Fragment, useCallback, useEffect, useState } from "react";
import { isNumber } from "lodash";
import { CircularProgress, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { formattedPrice } from "../../../helpers/money";
import { getAvailableSizes, sizeLabelsForCountry } from "../../../helpers/product";
import { useAppState } from "../../../state";
import { CurrencyCode } from "../../../generated/storefront";
import { Size } from "../../../types/product";
import { DashboardProductFragment, Frame, useGetArtistOrdersQuery } from "../../../generated/graphql";
import { colors, fonts } from "../../../theme";
import { User } from "../../../types/user";
import { getProfitForProduct, getSalesForProduct, getSalesForProductSize } from "../helpers";
import { getIdNumber } from "../../../helpers/shopify";
import { getPageViews } from "../../../services/API/analytics";

const Stats = ({ user, product }: { user: User; product: DashboardProductFragment; isMobileScreen: boolean }) => {
  const { countryOfUser } = useAppState();
  const [profit, setProfit] = useState<number | undefined>();
  const [sold, setSold] = useState<number | undefined>();
  const [views, setViews] = useState<number | undefined>();
  const [variantsSold, setVariantsSold] = useState<Record<string, number>>({});
  const { data, loading, fetchMore } = useGetArtistOrdersQuery({
    variables: {
      query: user.id,
    },
  });
  const hasMore = data?.orders?.pageInfo?.hasNextPage;
  const afterCursor = data?.orders?.pageInfo?.endCursor;
  const loadingOrders = hasMore || loading;

  const setStats = useCallback(() => {
    const artistOrders = data?.orders?.nodes;
    if (artistOrders) {
      const productId = getIdNumber(product.id);
      const salesCount = getSalesForProduct(artistOrders, productId);
      const profitForProduct = getProfitForProduct(artistOrders, productId);
      setSold(salesCount);
      setProfit(profitForProduct);
      const variantSales: Record<string, number> = {};
      getAvailableSizes(product.variants.nodes).forEach((size) => {
        const salesCountForVariant = getSalesForProductSize(artistOrders, productId, size);
        variantSales[size] = salesCountForVariant;
      });
      setVariantsSold(variantSales);
    }
  }, [data, product.id, product.variants.nodes]);

  const findVariants = (size: Size) => {
    const variant = product.variants.nodes.filter((variant) => {
      const sizeVariant = variant.selectedOptions.find((o) => o.name === "size");
      const frameVariant = variant.selectedOptions.find((o) => o.name === "frame");
      return sizeVariant?.value === size && frameVariant?.value === Frame.Unframed;
    });
    if (!variant) throw Error("No variant found");
    return variant;
  };

  const getViews = useCallback(async () => {
    const { data } = await getPageViews({ page: "products", id: getIdNumber(product.id), handle: product.handle });
    setViews(data.pageViews);
  }, [product.handle, product.id]);

  useEffect(() => {
    setStats();
  }, [setStats]);

  useEffect(() => {
    getViews();
  }, [getViews]);

  useEffect(() => {
    if (hasMore && afterCursor) {
      fetchMore({
        variables: {
          query: user.id,
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMore, user.id]);

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2}>
        <Stack
          gap={1}
          border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
          bgcolor={{ xs: colors.cardGrey, md: colors.white }}
          borderRadius={4}
          padding={2}
          flex={1}
        >
          <Typography fontSize={{ xs: 12, md: 16 }}>Profit</Typography>
          {isNumber(profit) && !loadingOrders ? (
            <Typography fontSize={{ xs: 26, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
              {formattedPrice(profit, CurrencyCode.Gbp)}
            </Typography>
          ) : (
            <Stack width={20}>
              <CircularProgress size="small" />
            </Stack>
          )}
        </Stack>
        <Stack
          gap={1}
          border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
          bgcolor={{ xs: colors.cardGrey, md: colors.white }}
          borderRadius={4}
          padding={2}
          flex={1}
        >
          <Typography fontSize={{ xs: 12, md: 16 }}>Sales</Typography>
          {sold !== undefined && !loadingOrders ? (
            <Typography fontSize={{ xs: 26, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
              {sold}
            </Typography>
          ) : (
            <Stack width={20}>
              <CircularProgress size="small" />
            </Stack>
          )}
        </Stack>
        <Stack
          gap={1}
          border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
          bgcolor={{ xs: colors.cardGrey, md: colors.white }}
          borderRadius={4}
          padding={2}
          flex={1}
        >
          <Typography fontSize={{ xs: 12, md: 16 }}>Views</Typography>
          {views !== undefined ? (
            <Typography fontSize={{ xs: 26, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
              {views}
            </Typography>
          ) : (
            <Stack width={20}>
              <CircularProgress size="small" />
            </Stack>
          )}
        </Stack>
      </Stack>

      <Grid container border={`1px solid ${colors.grey10}`} borderRadius={4} overflow="hidden">
        <Grid item xs={3} paddingX={2} paddingY={1} bgcolor={colors.snowWhite} borderBottom={`1px solid ${colors.grey10}`}>
          <Typography fontSize={12}>Size</Typography>
        </Grid>
        <Grid item xs={3} paddingX={2} paddingY={1} bgcolor={colors.snowWhite} borderBottom={`1px solid ${colors.grey10}`}>
          <Typography fontSize={12}>Price</Typography>
        </Grid>
        <Grid item xs={2} paddingX={2} paddingY={1} bgcolor={colors.snowWhite} borderBottom={`1px solid ${colors.grey10}`}>
          <Typography fontSize={12}>Sold</Typography>
        </Grid>
        <Grid item xs={4} paddingX={2} paddingY={1} bgcolor={colors.snowWhite} borderBottom={`1px solid ${colors.grey10}`}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={0.5}>
            <Typography fontSize={12}>Commission</Typography>
            <Tooltip
              title={
                <Typography>
                  Please note commission is 30% of the print sale price so may vary depending on discounts and/or currency
                  conversions
                </Typography>
              }
            >
              <InfoOutlined fontSize="inherit" />
            </Tooltip>
          </Stack>
        </Grid>

        {getAvailableSizes(product.variants.nodes).map((size) => (
          <Fragment key={size}>
            <Grid item xs={3} padding={2} borderBottom={`1px solid ${colors.grey10}`}>
              <Typography variant="body2">{sizeLabelsForCountry(countryOfUser)[size].shortHand}</Typography>
            </Grid>
            <Grid item xs={3} padding={2} borderBottom={`1px solid ${colors.grey10}`}>
              <Typography variant="body2">{formattedPrice(findVariants(size)[0].price, CurrencyCode.Gbp)}</Typography>
            </Grid>
            <Grid item xs={2} padding={2} borderBottom={`1px solid ${colors.grey10}`}>
              <Typography variant="body2">{variantsSold[size]}</Typography>
            </Grid>
            <Grid item xs={4} padding={2} borderBottom={`1px solid ${colors.grey10}`}>
              <Typography variant="body2" align="right">
                {formattedPrice(Number(findVariants(size)[0].price) * 0.3, CurrencyCode.Gbp)}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Stack>
  );
};

export default Stats;
