import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { Product } from "schema-dts";
import { User } from "../../types/user";
import { getHighestPrice, getLowestPrice } from "../../helpers/prices";
import { roundPrice } from "../../helpers/money";
import { CurrencyCode, ProductPageProductFragment } from "../../generated/storefront";

type Props = {
  id?: string;
  product: ProductPageProductFragment;
  artist: User;
};

const MetaData = ({ id, product, artist }: Props) => {
  const productLink = `https://www.goodmoodprints.io/products/${product.handle}`;
  const image = product.images.nodes[0];
  const variants = useMemo(() => (product ? product.variants.nodes : []), [product]);
  const lowestPrice = getLowestPrice(variants);
  const hightestPrice = getHighestPrice(variants);

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<Product>({
            "@context": "https://schema.org",
            "@type": "Product",
            name: product.title,
            description: product.description || product.title,
            url: productLink,
            image: image.src,
            brand: artist.firstName + " " + artist.lastName,
            offers: {
              "@type": "AggregateOffer",
              price: lowestPrice,
              lowPrice: lowestPrice,
              highPrice: hightestPrice,
              priceCurrency: CurrencyCode.Gbp,
              availability: "https://schema.org/InStock",
            },
          }),
        ]}
      >
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.description || product.title} />
        <meta property="og:url" content={productLink} />
        <meta property="og:image" content={image.src} />
        <meta property="og:google_product_category" content="500044" />
        <meta property="product:brand" content={`${artist.firstName} ${artist.lastName}`} />
        <meta property="product:availability" content="in stock" />
        <meta property="product:price:amount" content={`${roundPrice(lowestPrice)}`} />
        <meta property="product:price:currency" content={CurrencyCode.Gbp} />
        <meta property="og:price" content={`${roundPrice(lowestPrice)}`} />
        <meta property="og:priceCurrency" content={CurrencyCode.Gbp} />
        <meta property="g:price" content={`${roundPrice(lowestPrice)} ${CurrencyCode.Gbp}`} />
        <meta itemProp="price" content={lowestPrice.toString()} />
        <meta itemProp="lowPrice" content={lowestPrice.toString()} />
        <meta itemProp="highPrice" content={hightestPrice.toString()} />
        <meta itemProp="priceCurrency" content={CurrencyCode.Gbp} />
        <meta property="product:retailer_item_id" content={id} />
        <meta property="product:category" content="500044" />
      </Helmet>
    </>
  );
};

export default MetaData;
