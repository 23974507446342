import { BulkOrderFragment } from "../../generated/graphql";
import { getProductItemsFromOrders } from "../../helpers/orders";
import { getOrderItemTotals } from "../../helpers/prices";
import { Size } from "../../types/product";

export const getProfitForProduct = (orders: BulkOrderFragment[], productId: string) => {
  if (!orders.length) return 0;
  const { commissionTotal } = getOrderItemTotals(getProductItemsFromOrders(orders || [], productId));
  return commissionTotal;
};

export const getSalesForProduct = (orders: BulkOrderFragment[], productId: string) => {
  const productOrders = getProductItemsFromOrders(orders || [], productId);
  return productOrders.reduce((acc, curr) => acc + curr.quantity, 0);
};

export const getSalesForProductSize = (orders: BulkOrderFragment[], productId: string, size: Size) => {
  const productOrders = getProductItemsFromOrders(orders || [], productId);
  const sizeVariantOrders = productOrders.filter((order) => order.variantTitle?.includes(size));
  return sizeVariantOrders.reduce((acc, curr) => acc + curr.quantity, 0);
};
