import { Helmet } from "react-helmet";
import { Link, useSearchParams } from "react-router-dom";
import { capitalize } from "lodash";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { colors } from "../../theme";
import { Image } from "../../components";
import { useGetBlogArticlesQuery } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";
import AutoResizeText from "../../components/AutoResizeText";
import Tabs from "../../components/Tabs";
import { truncateThreeLinesStyles } from "../../state/constants";

const Blogs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tag = searchParams.get("tag");
  const { data } = useGetBlogArticlesQuery({
    variables: {
      query: tag ? `tag:${tag}` : "",
    },
  });
  const articles = data?.articles.nodes || [];
  const onTabChange = (tab: string) => {
    if (tab === "all") {
      searchParams.delete("tag");
      setSearchParams(searchParams);
    } else {
      searchParams.set("tag", tab);
      setSearchParams(searchParams);
    }
  };

  const removeTag = () => {
    searchParams.delete("tag");
    setSearchParams(searchParams);
  };

  const tabs = [
    { label: "All", value: "all" },
    { label: "Interviews", value: "interview" },
    { label: "Guides", value: "guide" },
  ];

  return (
    <>
      <Helmet>
        <title>GoodNews | GoodMood</title>
        <meta
          name="description"
          content="Discover stories, insights, and inspiration from our community of talented artists, curators and designers"
        />
        <link rel="canonical" href="https://goodmoodprints.io/goodnews" />
      </Helmet>
      <Stack gap={{ xs: 2, md: 4 }} alignItems="center" paddingX={2} paddingTop={2} paddingBottom={4}>
        <Stack gap={{ xs: 1, md: 2 }} alignItems="center" width="100%">
          <AutoResizeText text="GoodNews" align="center" />
          <Typography width={{ xs: "100%", md: "50%" }} align="center" fontSize={{ xs: 14, md: 20 }}>
            Discover stories, insights, and inspiration from our community of talented artists, curators and designers
          </Typography>
        </Stack>
        <Stack direction="row" overflow="auto" width="100%" justifyContent="center">
          {tag && !tabs.find((t) => t.value === tag) && (
            <Chip
              label={
                <Stack direction="row" gap={1} alignItems="center" paddingY={{ xs: 0, md: 0.5 }} paddingX={1}>
                  <Typography fontSize={14}>{capitalize(tag)}</Typography>
                  <Close fontSize="inherit" onClick={removeTag} sx={{ cursor: "pointer" }} />
                </Stack>
              }
              size="small"
              sx={{ color: colors.white, backgroundColor: colors.black, borderRadius: 10 }}
            />
          )}
          <Tabs onTabChange={onTabChange} selectedTab={tag || "all"} tabs={tabs} style={{ overflow: "initial" }} />
        </Stack>
      </Stack>
      <Grid container spacing={3} rowSpacing={{ xs: 4, md: 5 }} paddingY={0} paddingX={{ xs: 2, md: 5 }} marginBottom={4}>
        {articles.map((article) => (
          <Grid item key={article.id} xs={12} md={4}>
            <Link to={`/goodnews/${getIdNumber(article.id, "Article")}`}>
              <Stack height={{ xs: "80vw", md: "26vw" }} overflow="hidden">
                <Image src={article.image?.url} alt={article.image?.altText || "blog"} />
              </Stack>
              <Stack gap={2} paddingTop={{ xs: 2, md: 2 }}>
                <Stack gap={1}>
                  <Typography variant="h4">{article.title}</Typography>
                  <Typography variant="subtitle2" color={colors.grey60} style={truncateThreeLinesStyles}>
                    {article.excerpt}
                  </Typography>
                </Stack>
                {article.tags.length > 0 && (
                  <Stack direction="row" gap={0.5}>
                    {article.tags.map((tag) => (
                      <Link key={tag} to={`/goodnews?tag=${tag}`}>
                        <Chip
                          label={capitalize(tag)}
                          size="small"
                          sx={{
                            color: colors.darkPurple,
                            backgroundColor: colors.purple20,
                            fontSize: 12,
                            borderRadius: 1,
                          }}
                        />
                      </Link>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Blogs;
