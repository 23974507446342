import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AddRounded, CancelOutlined, CheckCircleOutlineOutlined, Edit } from "@mui/icons-material";
import mobileHeader from "../../assets/images/header.webp";
import placeholder from "../../assets/images/placeholder.webp";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DragAndDrop from "../../components/DragAndDrop";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import { Form } from "../../components/Layout";
import { validate } from "../../helpers/formValidation";
import { storage } from "../../services/Firebase";
import { updateUser } from "../../services/Firebase/users";
import { colors } from "../../theme";
import { User } from "../../types/user";
import { EmailTags } from "../../types/email";
import { checkUsersNewPermalink } from "../../services/Firebase/auth";
import Dropdown from "../../components/Form/Dropdown";
import { addContact } from "../../services/Klaviyo";
import { updateProduct } from "../../services/API";
import { useGetArtistProductsLazyQuery } from "../../generated/graphql";
import AutoResizeText from "../../components/AutoResizeText";

type Props = {
  isMobileScreen: boolean;
  user: User;
  setUser: Dispatch<SetStateAction<User | null>>;
  imageUrl: string;
  setImageUrl: (url: string) => void;
};

const Details = ({ isMobileScreen, user, setUser, imageUrl, setImageUrl }: Props) => {
  const [state, setState] = useState<Partial<User>>({});
  const [updated, setUpdated] = useState(false);
  const [checkingPermalink, setCheckingPermalink] = useState(false);
  const [permalinkAccepted, setPermalinkAccepted] = useState(true);
  const [vatRegistered, setVATRegistered] = useState(Boolean(user.vatRegistered));
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateErrors, setUpdateErrors] = useState<Partial<User>>();
  const [error, setError] = useState("");
  const [countries, setCountries] = useState<{ country: string; cities: string[]; iso2: string }[]>([]);
  const [getArtistProducts] = useGetArtistProductsLazyQuery();
  const disableSave = (!edited && !updated) || Boolean(updateErrors?.permalink) || checkingPermalink;

  const uploadImage = (files: File[]) => {
    const image = files[0];
    const fileTypes = ["jpg", "jpeg", "png"];
    const fileExtension = image.name.split(".").pop()?.toLowerCase();

    if (fileExtension && !fileTypes.includes(fileExtension)) {
      return alert("Incorrect file extension, must be either jpg or png");
    }
    if (image.size > 1000000) {
      return alert("File size must be less than 1MB");
    }
    if (image) {
      const artworkReference = new Date().getTime() + image.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const storageRef = ref(storage, `userProfiles/${artworkReference}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => console.log(error),
        () => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setImageUrl(downloadURL);
            setEdited(true);
          });
        }
      );
    }
  };

  const updateProductsArtistName = async (artistId: string, artistName: string) => {
    const { data } = await getArtistProducts({
      variables: {
        limit: 250,
        query: `vendor:${artistId}`,
      },
      fetchPolicy: "no-cache",
    });
    if (!data) return;
    const nameUpdated = data.products.nodes.some((product) => product.artistName?.value !== artistName);
    if (!nameUpdated) return;
    const promises = data.products.nodes.map(async (product) => {
      await updateProduct(product.id, { metafields: [{ namespace: "custom", key: "artist_name", value: artistName }] });
    });
    await Promise.all(promises);
  };

  const onUpdateUser = async () => {
    try {
      setLoading(true);
      setUpdateErrors({});
      setError("");
      const image = imageUrl ? { image: imageUrl } : {};
      const { firstName, lastName, about, email, paypalEmail, permalink, city, country } = state;
      const validation = validate({ firstName, lastName, about, email, paypalEmail, permalink, city, country });
      if (validation.error) {
        setLoading(false);
        setUpdateErrors(validation.errors);
        return;
      }
      const fName = firstName?.trim();
      const lName = lastName?.trim();
      const updatedUser = await updateUser(user.id, { ...state, ...image, firstName: fName, lastName: lName });
      await updateProductsArtistName(user.id, `${fName} ${lName}`);
      if (email && email !== user.email) {
        const tags = [EmailTags.Artist];
        if (user.hasProducts) tags.push(EmailTags.HasProducts);
        await addContact({ email, firstName: fName, lastName: lName, tags });
      }
      setUser(updatedUser);
      setUpdated(true);
      setEdited(false);
      setLoading(false);
      setTimeout(() => setUpdated(false), 2000);
    } catch (error: any) {
      setError(error.message as string);
      setLoading(false);
    }
  };

  const handleChange = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    setUpdateErrors({ ...updateErrors, [name]: "" });
    setState({ ...state, [name]: value });
    setEdited(true);
  };

  const handleVATChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateErrors({ ...updateErrors, vatRegistered: {} });
    setState({ ...state, vatRegistered: { ...state.vatRegistered, [name]: value } });
    setEdited(true);
  };

  const handleVATAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateErrors({ ...updateErrors, vatRegistered: {} });
    setState({
      ...state,
      vatRegistered: { ...state.vatRegistered, registeredAddress: { ...state.vatRegistered?.registeredAddress, [name]: value } },
    });
    setEdited(true);
  };

  const handlePermalinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") setState({ ...state, permalink: "" });
    if (/^[a-z-.0-9]+$/.test(value)) {
      setState({ ...state, permalink: value });
    }
    setEdited(true);
  };

  const handleLocationChange = (name: string, value: string) => {
    if (name === "country") {
      setState({ ...state, country: value, city: "" });
    } else {
      setState({ ...state, city: value });
    }
    setUpdateErrors({ ...updateErrors, [name]: "" });
    setEdited(true);
  };

  const checkPermalink = async (permalink: string) => {
    setCheckingPermalink(true);
    const alreadyExists = await checkUsersNewPermalink(user.id, permalink);
    if (alreadyExists) {
      setUpdateErrors({ ...updateErrors, permalink: "Permalink already exists" });
    } else {
      setUpdateErrors({ ...updateErrors, permalink: "" });
    }
    setPermalinkAccepted(!alreadyExists);
    setCheckingPermalink(false);
  };

  useDebounce(
    () => {
      state.permalink && checkPermalink(state.permalink);
    },
    600,
    [state.permalink]
  );

  const getCountries = async () => {
    const allCountries = await axios.get("https://countriesnow.space/api/v0.1/countries");
    setCountries(allCountries.data.data);
  };

  useEffect(() => {
    if (vatRegistered !== Boolean(user.vatRegistered)) {
      setEdited(true);
    }
    if (vatRegistered) {
      setState({ ...state, vatRegistered: { registeredName: "", vatNumber: "" } });
    } else {
      setState({ ...state, vatRegistered: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatRegistered]);

  useEffect(() => {
    setState(user);
  }, [user]);

  useEffect(() => {
    if (countries.length === 0) {
      getCountries();
    }
  }, [countries]);

  const countryOptions = countries.map((c) => ({ value: c.iso2, label: c.country }));
  const cityOptions = countries.find((c) => c.iso2 === state.country)?.cities.map((city) => ({ value: city, label: city }));

  const ColourRadio = ({ colour }: { colour: string }) => (
    <Box
      bgcolor={colour}
      border={`1px solid ${colors.white}`}
      borderRadius="100%"
      height={26}
      width={26}
      position="relative"
      onClick={() => handleChange({ target: { name: "backgroundColour", value: colour } })}
      sx={{
        cursor: "pointer",
        "::after": {
          content: '""',
          display: "block",
          width: "calc(100% + 6px)",
          height: "calc(100% + 6px)",
          position: "absolute",
          top: "-5px",
          left: "-5px",
          border: `2px solid ${colors.white}`,
          borderColor: state.backgroundColour === colour ? colors.white : "transparent",
          borderRadius: "100%",
        },
      }}
    ></Box>
  );

  return (
    <Stack direction={{ xs: "column", md: "row" }} paddingX={{ xs: 2, md: 0 }}>
      <Stack gap={2} width={{ xs: "100%", md: "40%" }} paddingTop={2} paddingRight={{ xs: 0, md: 3 }} paddingBottom={12}>
        <Typography variant="h2">Account details</Typography>
        <Form>
          <Stack gap={2} direction="row">
            <Input
              placeholder="First name"
              id="firstName"
              name="firstName"
              label="First name"
              value={state.firstName}
              onChange={handleChange}
              error={updateErrors?.firstName}
            />
            <Input
              placeholder="Last name"
              id="lastName"
              name="lastName"
              label="Last name"
              value={state.lastName}
              onChange={handleChange}
              error={updateErrors?.lastName}
            />
          </Stack>
          <TextArea
            placeholder="A little description about yourself"
            label="About me"
            name="about"
            value={state.about}
            onChange={handleChange}
            error={updateErrors?.about}
          />
          <Stack gap={1} direction="row" width="100%">
            <Dropdown
              label="Country"
              name="country"
              onChange={(event) => handleLocationChange("country", event.value)}
              options={countryOptions}
              value={countryOptions?.find((c) => c.value === state.country)}
              isSearchable
              error={updateErrors?.country}
              width="auto"
              style={{ flex: 1 }}
            />
            <Dropdown
              label="City"
              name="city"
              onChange={(event) => handleLocationChange("city", event.value)}
              options={cityOptions || []}
              value={cityOptions?.find((c) => c.value === state.city) || { value: "", label: "" }}
              disabled={!state.country}
              isSearchable
              error={updateErrors?.city}
              width="auto"
              style={{ flex: 1 }}
            />
          </Stack>
          <FormControl error={Boolean(updateErrors?.permalink)}>
            <Stack gap={0.5}>
              <FormLabel htmlFor="permalink">
                Permalink{" "}
                <Typography fontSize={12} sx={{ color: colors.grey60 }} component="span">
                  (name in your profile url - no spaces or special characters)
                </Typography>
              </FormLabel>
              <TextField
                placeholder="Permalink"
                id="permalink"
                name="permalink"
                variant="outlined"
                type="text"
                onChange={handlePermalinkChange}
                value={state.permalink}
                error={Boolean(updateErrors?.permalink)}
                sx={{ ".MuiInputBase-input": { paddingLeft: 0 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color={colors.grey40}>goodmoodprints.io/artists/</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {checkingPermalink ? (
                        <CircularProgress size="small" />
                      ) : permalinkAccepted ? (
                        <CheckCircleOutlineOutlined fontSize="small" />
                      ) : (
                        <CancelOutlined fontSize="small" color="error" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <FormHelperText id="permalink-error-text">{updateErrors?.permalink}</FormHelperText>
          </FormControl>

          <Input
            placeholder="Email"
            id="email"
            name="email"
            label="Email"
            value={state.email}
            onChange={handleChange}
            error={updateErrors?.email}
          />
          <Input
            placeholder="Paypal email"
            id="paypalEmail"
            name="paypalEmail"
            label="Paypal email"
            value={state.paypalEmail}
            onChange={handleChange}
            error={updateErrors?.paypalEmail}
          />
          <div>
            <Checkbox
              name="vat"
              label="Are you VAT/GST registered?"
              onChange={({ target }) => setVATRegistered(target.checked)}
              checked={Boolean(state.vatRegistered)}
            />
            <Typography fontSize={12} sx={{ color: colors.grey60 }} paddingLeft={4.5}>
              (Please note you do not need to be VAT/GST registered to sell on GoodMood but we need to know if you are)
            </Typography>
          </div>
          {vatRegistered && (
            <>
              <Input
                placeholder="Registered name"
                id="registeredName"
                name="registeredName"
                label="Registered name"
                value={state.vatRegistered?.registeredName}
                onChange={handleVATChange}
                error={updateErrors?.vatRegistered?.registeredName}
              />
              <Input
                placeholder="VAT/GST number"
                id="vatNumber"
                name="vatNumber"
                label="VAT/GST number"
                value={state.vatRegistered?.vatNumber}
                onChange={handleVATChange}
                error={updateErrors?.vatRegistered?.vatNumber}
              />
              <FormLabel>Registered address (optional)</FormLabel>
              <Input
                placeholder="Line 1"
                id="addressLine1"
                name="addressLine1"
                value={state.vatRegistered?.registeredAddress?.addressLine1}
                onChange={handleVATAddressChange}
                error={updateErrors?.vatRegistered?.registeredAddress?.addressLine1}
              />
              <Input
                placeholder="Line 2"
                id="addressLine2"
                name="addressLine2"
                value={state.vatRegistered?.registeredAddress?.addressLine2}
                onChange={handleVATAddressChange}
                error={updateErrors?.vatRegistered?.registeredAddress?.addressLine2}
              />
              <Input
                placeholder="City"
                id="city"
                name="city"
                value={state.vatRegistered?.registeredAddress?.city}
                onChange={handleVATAddressChange}
                error={updateErrors?.vatRegistered?.registeredAddress?.city}
              />
              <Stack gap={1} direction="row" width="100%">
                <Input
                  placeholder="Postal code"
                  id="postalCode"
                  name="postalCode"
                  value={state.vatRegistered?.registeredAddress?.postalCode}
                  onChange={handleVATAddressChange}
                  error={updateErrors?.vatRegistered?.registeredAddress?.postalCode}
                  width="auto"
                  style={{ flex: 1 }}
                />
                <Dropdown
                  name="country"
                  placeholder="Country"
                  onChange={(event) => handleVATAddressChange(event)}
                  options={countryOptions}
                  value={countryOptions?.find((c) => c.value === state.vatRegistered?.registeredAddress?.country)}
                  isSearchable
                  error={updateErrors?.vatRegistered?.registeredAddress?.country}
                  width="auto"
                  style={{ flex: 1 }}
                />
              </Stack>
            </>
          )}
          {error && <Typography color={colors.error}>{error}</Typography>}
        </Form>

        <Box position="sticky" bottom={{ xs: 75, md: 0 }} paddingY={2} zIndex={1}>
          <Button size="medium" disabled={disableSave} loading={loading} onClick={onUpdateUser} fullWidth>
            {updated ? "Saved!" : "Save"}
          </Button>
        </Box>
      </Stack>

      {!isMobileScreen && (
        <Box bgcolor={colors.black} width={{ xs: "100%", md: "60%" }}>
          <Stack width="50%" minWidth={350} margin="auto" direction="row" marginTop={5} gap={2}>
            <Stack borderRadius={4} overflow="hidden">
              <Stack width="100%" position="relative">
                <img src={mobileHeader} alt="mobile header" width="100%" />
                <Box position="absolute" top="40%" left="50%" sx={{ transform: "translateX(-50%)" }}>
                  <Typography fontSize="0.8vw" color="#4B4D52" fontFamily="Roboto">
                    goodmoodprints.io/artist/{state.permalink || ""}
                  </Typography>
                </Box>
              </Stack>
              <DragAndDrop onImageDrop={uploadImage}>
                <Stack position="relative" sx={{ cursor: "pointer" }}>
                  <img
                    src={imageUrl || user.image || placeholder}
                    alt="profile"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover", height: "15vw", minHeight: 250 }}
                  />
                  {imageUrl || user.image ? (
                    <Box position="absolute" top={12} right={12}>
                      <IconButton style={{ backgroundColor: colors.grey02 }}>
                        <Edit fontSize="small" color="primary" />
                      </IconButton>
                    </Box>
                  ) : (
                    <Stack
                      position="absolute"
                      top="50%"
                      left="0"
                      sx={{ transform: "translateY(-50%)" }}
                      width="100%"
                      alignItems="center"
                    >
                      <Button secondary size="medium">
                        <Stack direction="row" gap={0.75}>
                          Add profile picture
                          <AddRounded fontSize="small" />
                        </Stack>
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </DragAndDrop>
              <Stack
                bgcolor={state.backgroundColour || colors.mint}
                padding={3}
                height="15vw"
                minHeight={250}
                justifyContent="flex-end"
              >
                <AutoResizeText text={`${user.firstName} ${user.lastName}`} maxSize={70} />
                <Typography fontSize={14}>{user.about}</Typography>
              </Stack>
            </Stack>
            <Stack gap={1.5} justifyContent="center">
              <ColourRadio colour={colors.mint} />
              <ColourRadio colour={colors.peach} />
              <ColourRadio colour={colors.blue} />
              <ColourRadio colour={colors.lemon} />
              <ColourRadio colour={colors.cream} />
              <ColourRadio colour={colors.cardGrey} />
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default Details;
