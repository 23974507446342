import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { getUsers } from "../../services/Firebase";
import { User } from "../../types/user";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Loader } from "../../components/Loader";
import ArtistProducts from "./ArtistProducts";
import Tabs from "../../components/Tabs";
import { Medium } from "../../types/product";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";
import { mediumOptions } from "../../state/constants";
import AutoResizeText from "../../components/AutoResizeText";
import { useAppState } from "../../state";

const Artists = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useAppState();
  const [artists, setArtists] = useState<User[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<Medium | "all">("all");
  const [lastUser, setLastUser] = useState<QueryDocumentSnapshot<DocumentData>>();
  const mediumTabs = [{ label: "All", value: "all" }, ...mediumOptions];

  const fetchArtists = async (fetchMore = true) => {
    if (!fetchMore) {
      setArtists([]);
    }
    setLoading(true);
    const { users, lastVisible } = await getUsers({
      lastUser: fetchMore ? lastUser : undefined,
      orderBy: { field: "salesCount", direction: "desc" },
      filters: { hasProducts: true, ...(selectedTab === "all" ? {} : { mediums: selectedTab }) },
    });
    setHasMore(Boolean(users.length));
    if (fetchMore) {
      const currentArtists = artists ? [...artists] : [];
      const allArtists = [...currentArtists, ...users];
      setArtists(allArtists);
    } else {
      setArtists(users);
    }
    setLastUser(lastVisible);
    setLoading(false);
  };

  useLoadMoreOnScroll(ref, fetchArtists, hasMore, loading);

  useEffect(() => {
    if (!artists && !loading) {
      fetchArtists();
    }
  });

  useEffect(() => {
    if (!loading) {
      fetchArtists(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <>
      <Helmet>
        <title>Artists | GoodMood</title>
        <meta name="description" content="Explore our creative community" />
      </Helmet>
      <div ref={ref}>
        <Stack gap={{ xs: 1, md: 2 }} alignItems="center" width="100%" paddingX={{ xs: 2, md: 3 }} paddingY={3}>
          <AutoResizeText text="Artists" align="center" maxSize={isMobileScreen ? 50 : 85} />
          <Typography width={{ xs: "100%", md: "50%" }} align="center" fontSize={{ xs: 14, md: 20 }}>
            We're an online creative community where you can discover, support and be inspired by your new favourite artists.
          </Typography>
        </Stack>

        <Box paddingBottom={{ xs: 3, md: 5 }}>
          <Stack direction="row" justifyContent="center">
            <Tabs
              onTabChange={(value) => setSelectedTab(value as Medium | "all")}
              tabs={mediumTabs}
              selectedTab={selectedTab}
              style={{ padding: "0 16px" }}
            />
          </Stack>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            rowSpacing={{ xs: 4, md: 6 }}
            paddingX={{ xs: 2, md: 5 }}
            paddingY={{ xs: 3, md: 5 }}
          >
            {artists?.map((artist) => (
              <Grid item xs={6} md={4} key={artist.id}>
                <ArtistProducts artist={artist} />
              </Grid>
            ))}
          </Grid>

          {loading ? (
            <Loader />
          ) : hasMore ? (
            <Stack width="100%" alignItems="center" padding={5}>
              <Button onClick={() => fetchArtists(true)}>Load more</Button>
            </Stack>
          ) : null}
        </Box>
      </div>
    </>
  );
};

export default Artists;
