import { match } from "ts-pattern";
import { colors, fonts } from "../../theme";
import { Box, Stack, Typography } from "@mui/material";

type Props = {
  status: "draft" | "live" | "archived" | "upcoming";
};

const StatusPill = ({ status }: Props) => {
  return match(status)
    .with("live", () => (
      <Stack direction="row" gap={0.75} alignItems="center">
        <Box bgcolor={colors.green} width={6} height={6} borderRadius="100%" />
        <Typography color={colors.green} fontSize={14} fontFamily={fonts.body}>
          Active
        </Typography>
      </Stack>
    ))
    .with("draft", () => (
      <Typography color={colors.grey80} fontSize={14} fontFamily={fonts.body}>
        Draft
      </Typography>
    ))
    .with("archived", () => (
      <Typography color={colors.grey80} fontSize={14} fontFamily={fonts.body}>
        Archived
      </Typography>
    ))
    .with("upcoming", () => (
      <Typography color={colors.grey80} fontSize={14} fontFamily={fonts.body}>
        Upcoming
      </Typography>
    ))
    .exhaustive();
};

export default StatusPill;
