import { ShoppingBagOutlined } from "@mui/icons-material";
import { Badge } from "@mui/material";
import BasketDrawer from "./BasketDrawer";
import { BasketFragment } from "../../generated/storefront";
import { NavItem } from "./styles";

type BasketNavItemProps = {
  basket?: BasketFragment;
  showBasket: boolean;
  setShowBasket: (value: boolean) => void;
  closeBasket: () => void;
  basketQuantity: number;
  isMobileScreen: boolean;
  viewOnly?: boolean;
};

const BasketNavItem = ({
  basket,
  showBasket,
  setShowBasket,
  closeBasket,
  basketQuantity,
  isMobileScreen,
  viewOnly,
}: BasketNavItemProps) => {
  return (
    <>
      <NavItem fontSize={14} onClick={() => setShowBasket(true)} style={{ position: "relative" }}>
        <Badge badgeContent={basketQuantity} color="info">
          <ShoppingBagOutlined fontSize={isMobileScreen ? "medium" : "small"} />
        </Badge>
      </NavItem>
      <BasketDrawer open={showBasket} basket={basket} onClose={closeBasket} viewOnly={viewOnly} />
    </>
  );
};

export default BasketNavItem;
