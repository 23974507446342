import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import {
  ProductSortKeys,
  SearchProductFragment,
  useGetProductsForProductCardLazyQuery,
  useSearchLazyQuery,
  useSearchQuery,
} from "../../generated/storefront";
import { productSearchFilter } from "../../helpers/product";
import { Loader } from "../../components/Loader";
import { useAppState } from "../../state";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";
import ProductCard from "../../components/ProductCard";
import { searchArtists } from "../../services/Firebase";
import { User } from "../../types/user";
import ArtistCard from "../../components/Card/Artist";

const Search = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { selectedCountry, isMobileScreen } = useAppState();
  const [products, setProducts] = useState<SearchProductFragment[]>();
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState<User[]>([]);
  const [loadingArtists, setLoadingArtists] = useState(false);
  const [serachParams] = useSearchParams();
  const searchQs = serachParams.get("qs") || "";
  const [getProducts] = useGetProductsForProductCardLazyQuery();
  const {
    data: favouriteProductsData,
    loading: loadingFavouriteProducts,
    fetchMore: fetchMoreFavouriteProducts,
  } = useSearchQuery({
    variables: { query: searchQs, limit: 24, country: selectedCountry, productFilters: { tag: "favourite" } },
    fetchPolicy: "network-only",
  });
  const [getOtherProducts, { data: otherProductsData, loading: loadingOtherProducts, fetchMore: fetchMoreOtherProducts }] =
    useSearchLazyQuery({
      variables: { query: searchQs + " NOT tag:favourite", limit: 24, country: selectedCountry },
      fetchPolicy: "network-only",
    });
  const favouriteProductsHasMore = favouriteProductsData?.search.pageInfo.hasNextPage || false;
  const favouriteProductsAfterCursor = favouriteProductsData?.search.pageInfo.endCursor || "";

  const otherProductsHasMore = otherProductsData?.search.pageInfo.hasNextPage || false;
  const otherProductsAfterCursor = otherProductsData?.search.pageInfo.endCursor || "";
  const otherProducts = otherProductsData?.search.nodes.filter(productSearchFilter) as SearchProductFragment[];

  const fetchMore = favouriteProductsHasMore ? fetchMoreFavouriteProducts : fetchMoreOtherProducts;
  const loadingProducts = loadingFavouriteProducts || loadingOtherProducts;
  const hasMore = favouriteProductsHasMore || otherProductsHasMore;
  const afterCursor = favouriteProductsHasMore ? favouriteProductsAfterCursor : otherProductsAfterCursor;

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    favouriteProductsHasMore || (otherProductsData ? otherProductsHasMore : false),
    favouriteProductsHasMore ? loadingFavouriteProducts : loadingOtherProducts
  );

  const getInitialProducts = async () => {
    setLoading(true);
    setLoadingArtists(true);
    const searchedArtists = await searchArtists(searchQs);
    setArtists(searchedArtists);
    setLoadingArtists(false);
    const artistIds = searchedArtists.map((artist) => artist.id);

    const { data: artistProductsData } = await getProducts({
      variables: {
        limit: 8,
        query: `vendor:${artistIds.join(",")}`,
        sortKey: ProductSortKeys.BestSelling,
        country: selectedCountry,
      },
    });

    if (!favouriteProductsData || !artistProductsData) return;

    const favouriteProducts = favouriteProductsData?.search.nodes.filter(productSearchFilter) as SearchProductFragment[];

    const combinedProducts = [
      ...(favouriteProducts || []),
      ...(artistProductsData?.products.nodes.filter((node) => products && !products.find((product) => product.id === node.id)) ||
        []),
    ];

    if (combinedProducts.length === 0) {
      getOtherProducts();
    } else {
      setProducts(combinedProducts);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteProductsData?.search, searchQs]);

  const totalProductCount = (products?.length || 0) + (otherProducts?.length || 0);

  return (
    <>
      <Helmet>
        <title>{`Search results for ${searchQs} | GoodMood`}</title>
      </Helmet>

      <Stack gap={5} paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 3 }} ref={ref}>
        {loadingArtists && loading ? (
          <Loader />
        ) : (
          <>
            {loadingArtists ? (
              <Loader />
            ) : (
              artists.length > 0 && (
                <Stack gap={3} width="100%">
                  <Typography variant="h2" align="center">
                    Artists
                  </Typography>

                  <Grid container spacing={1}>
                    {artists.map((artist) => (
                      <Grid item xs={12 / 2} md={12 / 4} key={artist.id}>
                        <ArtistCard artist={artist} size="small" imageHeight="200px" borderRadius={12} />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              )
            )}

            <Stack gap={3} width="100%">
              <Typography variant="h2" align="center">
                Products
              </Typography>
              {loading ? (
                <Loader />
              ) : totalProductCount === 0 ? (
                <Typography variant="h3" align="center">
                  No products found for "{searchQs}"
                </Typography>
              ) : (
                <Stack gap={3} alignItems="center">
                  <Typography variant="h4">
                    Showing {totalProductCount === 250 ? "250+" : totalProductCount} results for "{searchQs}"
                  </Typography>

                  <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                    {[...(products || []), ...(otherProducts || [])].map((product) => (
                      <Grid item xs={12 / 2} md={12 / 4} key={product.id}>
                        <ProductCard product={product} indent={isMobileScreen} />
                      </Grid>
                    ))}
                  </Grid>
                  {loadingProducts ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button
                        onClick={() =>
                          fetchMore({
                            variables: {
                              afterCursor,
                            },
                          })
                        }
                      >
                        Load more
                      </Button>
                    </Stack>
                  ) : null}
                  {!favouriteProductsHasMore && !otherProductsData && (
                    <Button onClick={() => getOtherProducts()}>Load more</Button>
                  )}
                </Stack>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default Search;
