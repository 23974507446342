import { PropsWithChildren, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { CheckRounded, Facebook, IosShareRounded, LinkRounded, LinkedIn, Pinterest, X } from "@mui/icons-material";
import { colors } from "../../theme";

const ShareIcon = ({ onClick, children, size }: PropsWithChildren<{ onClick: () => void; size: number }>) => {
  return (
    <IconButton
      onClick={onClick}
      color="primary"
      style={{ display: "flex", width: size, height: size, fontSize: size / 2, padding: 0, backgroundColor: colors.cardGrey }}
    >
      {children}
    </IconButton>
  );
};

const ShareIcons = ({
  isMobileScreen,
  text,
  pageUrl,
  size = 32,
  showLabels,
}: {
  isMobileScreen: boolean;
  text: string;
  pageUrl?: string;
  size?: number;
  showLabels?: boolean;
}) => {
  const [copied, setCopied] = useState(false);
  const url = pageUrl || window.location.href;

  const onCopyLink = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const onShareNative = () => {
    if (navigator.share) {
      navigator.share({
        text,
        url,
      });
    }
  };

  const onShareToX = () => {
    const shareUrl = `https://x.com/intent/post?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToLinkedIn = () => {
    const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(
      text
    )}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToPinterest = () => {
    const shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      url
    )}&description=${encodeURIComponent(text)}`;
    window.open(shareUrl, "_blank");
  };

  const shareIcons = [
    { label: "X", onClick: onShareToX, icon: <X fontSize="inherit" /> },
    { label: "Facebook", onClick: onShareToFacebook, icon: <Facebook fontSize="inherit" /> },
    { label: "LinkedIn", onClick: onShareToLinkedIn, icon: <LinkedIn fontSize="inherit" /> },
    { label: "Pinterest", onClick: onShareToPinterest, icon: <Pinterest fontSize="inherit" /> },
  ];

  return (
    <Stack gap={size / 32} direction="row" alignItems="center" justifyContent="center">
      <Stack gap={1} alignItems="center">
        <ShareIcon onClick={onCopyLink} size={size}>
          {copied ? <CheckRounded fontSize="inherit" /> : <LinkRounded fontSize="inherit" />}
        </ShareIcon>
        {showLabels && <Typography fontSize={10}>{copied ? "Copied" : "Copy link"}</Typography>}
      </Stack>
      {isMobileScreen && (
        <Stack gap={1} alignItems="center">
          <ShareIcon onClick={onShareNative} size={size}>
            <IosShareRounded fontSize="inherit" />
          </ShareIcon>
          {showLabels && <Typography fontSize={10}>Share to</Typography>}
        </Stack>
      )}
      {shareIcons.map(({ label, onClick, icon }) => (
        <Stack gap={1} alignItems="center" key={label}>
          <ShareIcon key={icon.toString()} onClick={onClick} size={size}>
            {icon}
          </ShareIcon>
          {showLabels && <Typography fontSize={10}>{label}</Typography>}
        </Stack>
      ))}
    </Stack>
  );
};

export default ShareIcons;
