import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { SetProductFragment, useGetProductsForSetLazyQuery } from "../../generated/storefront";
import { Loader } from "../../components/Loader";
import { useEffect, useMemo, useState } from "react";
import { getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import CollectionItem from "./CollectionItem";
import Button from "../../components/Button";
import useBasketActions from "../../hooks/useBasketActions";
import { colors } from "../../theme";
import { Frame, frames } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { useAppState } from "../../state";
import { Carousel, CarouselItem } from "../../components/SnapCarousel";
import { PlainMockUp } from "../../components/MockUps";
import FrameMockUp from "../../components/FrameMockUp";
import plainWall from "../../assets/images/mock-ups/plain-wall.webp";

const PrintSet = () => {
  const [searchParams] = useSearchParams();
  const productIds = useMemo(() => searchParams.get("products")?.split(",") || [], [searchParams]);
  const { navHeight, isMobileScreen, selectedCountry } = useAppState();
  const { addOrCreateBasket, loading: loadingBasket, error } = useBasketActions();
  const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>({});
  const [getProducts, { data, loading, called }] = useGetProductsForSetLazyQuery();
  const products = useMemo(() => data?.products?.nodes || [], [data]);

  const setSelectedVariant = (productId: string, variantId: string) =>
    setSelectedVariants({ ...selectedVariants, [productId]: variantId });

  const addAllItemsToBasket = () => {
    addOrCreateBasket(
      Object.values(selectedVariants).map((merchandiseId) => ({
        merchandiseId,
        quantity: 1,
      }))
    );
  };

  useEffect(() => {
    if (!called) {
      getProducts({
        variables: {
          query: `id:${productIds.join(" OR ")}`,
          country: selectedCountry,
        },
      });
    }
  }, [productIds, selectedCountry, getProducts, called]);

  useEffect(() => {
    const defaultVariants: Record<string, string> = {};
    products.forEach((product) => {
      const defaultVariant = product.variants.nodes.find((variant) => {
        const { size, frame } = getSizeAndFrame(variant.selectedOptions);
        return size === getAvailableSizes(product.variants.nodes)[0] && frame === frames[1];
      });
      if (defaultVariant) defaultVariants[product.id] = defaultVariant.id;
    });
    setSelectedVariants(defaultVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (!data || loading)
    return (
      <Box padding={2}>
        <Loader />
      </Box>
    );

  const subtotal = products.reduce((acc, product) => {
    const variant = product.variants.nodes.find((v) => v.id === selectedVariants[product.id]);
    return acc + (variant ? Number(variant.priceV2.amount) : 0);
  }, 0);
  const currencyCode = products[0]?.variants.nodes[0].priceV2.currencyCode;
  const discountPct = 0.15;
  const getFrameColour = (product: SetProductFragment) => {
    const frame = selectedVariants[product.id]
      ? (getSizeAndFrame(product.variants.nodes.find((v) => v.id === selectedVariants[product.id])?.selectedOptions || [])
          .frame as Frame) || Frame.Black
      : Frame.Black;
    return frame;
  };

  const SetMockUp = () => (
    <Stack width="100%" height={{ xs: "100%", md: "auto" }} position="relative">
      <img src={plainWall} alt="plain wall" width="100%" height="auto" />
      <Stack
        direction="row"
        gap={2}
        position="absolute"
        top="50%"
        left="50%"
        width="100%"
        padding={{ xs: 2, md: 5 }}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Box paddingBottom={{ xs: 5, md: 9 }} width="50%">
          <FrameMockUp frame={getFrameColour(products[0])} image={products[0].images.nodes[0]} shadow />
        </Box>

        <Box paddingTop={{ xs: 5, md: 9 }} width="50%">
          <FrameMockUp frame={getFrameColour(products[1])} image={products[1].images.nodes[0]} shadow />
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Helmet>
        <title>Sets | GoodMood</title>
      </Helmet>
      <Stack direction={{ xs: "column", md: "row" }} width="100%" height={{ xs: "auto", md: `calc(100vh - ${navHeight}px)` }}>
        {products.length > 0 ? (
          <>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              {isMobileScreen ? (
                products.length > 0 && (
                  <Box height="50vh">
                    <Carousel
                      items={[
                        {
                          key: "collection-image",
                          component: <SetMockUp />,
                        },
                        ...products.map((product) => {
                          return {
                            key: product.id,
                            component: <PlainMockUp image={product.images.nodes[0]} frame={getFrameColour(product)} isMobile />,
                          };
                        }),
                      ]}
                      renderItem={({ item, isSnapPoint }) => {
                        return (
                          <CarouselItem key={item.key} isSnapPoint={isSnapPoint}>
                            {item.component}
                          </CarouselItem>
                        );
                      }}
                    />
                  </Box>
                )
              ) : (
                <SetMockUp />
              )}
            </Stack>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              <Box overflow={{ xs: "initial", md: "auto" }} paddingTop={2}>
                <Stack gap={3}>
                  <Typography variant="h2" component="h1" paddingX={{ xs: 2, md: 5 }}>
                    Personalised Set
                  </Typography>
                  <Stack gap={2}>
                    {products.map((product, index) => (
                      <CollectionItem
                        product={product}
                        setSelectedVariant={setSelectedVariant}
                        lastChild={index === products.length - 1}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Box
                  paddingY={1.5}
                  paddingX={{ xs: 2, md: 5 }}
                  bgcolor={colors.white}
                  position="sticky"
                  bottom={0}
                  borderTop={`1px solid ${colors.grey10}`}
                >
                  <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                    {products.length && (
                      <Stack gap={{ xs: 0.5, md: 1 }} direction="row" alignSelf="start">
                        <Typography
                          variant="h4"
                          alignSelf="start"
                          style={{ textDecoration: "line-through" }}
                          color="text.disabled"
                        >
                          {formattedPrice(subtotal, currencyCode)}
                        </Typography>
                        <Typography variant="h4" color={colors.red}>
                          {formattedPrice(subtotal * (1 - discountPct), currencyCode)} · {discountPct * 100}% OFF
                        </Typography>
                      </Stack>
                    )}

                    <Button fullWidth onClick={addAllItemsToBasket} loading={loadingBasket}>
                      Add all to basket
                    </Button>
                    {error && <Typography color={colors.error}>{error}</Typography>}
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </>
        ) : (
          <Box padding={2} width="100%">
            <Loader />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default PrintSet;
