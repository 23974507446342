import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { uniqBy } from "lodash";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { useAppState } from "../../state";
import { User } from "../../types/user";
import placeholder from "../../assets/images/placeholder.webp";
import { ProductSortKeys, useGetProductsByArtistQuery } from "../../generated/storefront";
import LazyLoadImage from "../../components/Image/LazyLoadImage";
import { colors } from "../../theme";
import { scrollbarStyles } from "../../state/constants";

const ProductLink = styled(Link)`
  display: flex;

  &:hover > div {
    background-color: ${colors.grey10};
  }
`;

const ArtistProducts = ({ artist }: { artist: User }) => {
  const { selectedCountry, isMobileScreen } = useAppState();
  const ref = useRef<HTMLDivElement>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showArrows, setShowArrows] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [endOfScroll, setEndOfScroll] = useState(false);
  const { data, loading, fetchMore } = useGetProductsByArtistQuery({
    variables: {
      limit: 6,
      query: `vendor:${artist.id}`,
      sortKey: ProductSortKeys.CreatedAt,
      country: selectedCountry,
    },
  });
  const products = uniqBy(data?.products.nodes, "id");

  const goBack = () => {
    if (!ref.current || !products) return;
    const scrollDistance = ref.current.scrollWidth / products.length;
    ref.current?.scrollBy(-scrollDistance, 0);
  };

  const goForward = () => {
    if (!ref.current || !products) return;
    const scrollDistance = ref.current.scrollWidth / products.length;
    ref.current?.scrollBy(scrollDistance, 0);
  };

  const getMoreProducts = useCallback(() => {
    if (!ref.current) return;
    if (
      ref.current.scrollLeft > ref.current.scrollWidth - ref.current.clientWidth - 100 &&
      data?.products.pageInfo.hasNextPage &&
      !loading
    ) {
      fetchMore({ variables: { afterCursor: data?.products.pageInfo.endCursor } });
    }
  }, [data, fetchMore, loading]);

  useEffect(() => {
    if (endOfScroll) getMoreProducts();
  }, [endOfScroll, getMoreProducts]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener("scroll", () => {
      if (!ref.current) return;
      setScrollPosition(ref.current.scrollLeft);
      if (ref.current.scrollLeft > ref.current.scrollWidth - ref.current.clientWidth - 100) {
        setEndOfScroll(true);
      } else {
        setEndOfScroll(false);
      }
    });
  });

  return (
    <Stack height="100%" onMouseOver={() => setShowArrows(true)} onMouseLeave={() => setShowArrows(false)}>
      <Link to={`/artists/${artist.permalink}`}>
        <Box height={{ xs: "36vw", md: "20vw" }} width="100%" position="relative">
          {!imageLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              animation="wave"
              variant="rectangular"
              sx={{ position: "absolute", bgcolor: colors.grey02 }}
            />
          )}
          <LazyLoadImage
            src={artist.image || placeholder}
            alt={`${artist.firstName} ${artist.lastName}`}
            width="100%"
            height="100%"
            onLoad={() => setImageLoaded(true)}
            style={{ objectFit: "cover" }}
          />
          {imageLoaded && (
            <Stack
              width="100%"
              height="100%"
              padding={{ xs: 1, md: 2 }}
              position="absolute"
              bottom={0}
              left={0}
              justifyContent="flex-end"
              sx={{ background: "linear-gradient(15deg, #20171180 0%, rgba(0, 0, 0, 0) 30%);" }}
            >
              <Typography fontSize={{ xs: 14, md: 18 }} color={colors.white} sx={{ textShadow: "0px 0px 30px #00000060" }}>
                {artist.firstName} {artist.lastName}
              </Typography>
            </Stack>
          )}
        </Box>
      </Link>
      <Box position="relative" height="100%">
        <Stack
          direction="row"
          gap={{ xs: 0.25, md: 0.5 }}
          ref={ref}
          height="100%"
          overflow="auto"
          sx={{ ...scrollbarStyles, scrollBehavior: "smooth" }}
        >
          {loading || !products ? (
            <>
              <Skeleton variant="rectangular" width="100%" height={isMobileScreen ? "20vw" : "10vw"} />
              <Skeleton variant="rectangular" width="100%" height={isMobileScreen ? "20vw" : "10vw"} />
              <Skeleton variant="rectangular" width="100%" height={isMobileScreen ? "20vw" : "10vw"} />
            </>
          ) : (
            <>
              {products.map((product) => (
                <ProductLink to={`/products/${product.handle}`} key={product.id}>
                  <Box
                    bgcolor={colors.cardGrey}
                    paddingX={{ xs: 1, md: 3 }}
                    paddingY={{ xs: 1, md: 1.5 }}
                    minWidth={{ xs: "17vw", md: "10vw" }}
                  >
                    <LazyLoadImage
                      src={product.images.nodes[0].src + "&width=200"}
                      alt={product.images.nodes[0].altText || product.title}
                      width="100%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </Box>
                </ProductLink>
              ))}
              {products.length < 3 &&
                [...Array(3 - products.length).fill("")].map((i) => (
                  <Box key={i} minWidth={{ xs: "17vw", md: "10vw" }} height={isMobileScreen ? "20vw" : "10vw"}></Box>
                ))}

              {!isMobileScreen && products.length > 3 && showArrows && (
                <>
                  <Box position="absolute" left={2} top="50%" sx={{ transform: "translateY(-50%)" }}>
                    <IconButton
                      onClick={goBack}
                      size="small"
                      disabled={scrollPosition === 0}
                      aria-label="view previous products"
                      sx={{ backgroundColor: colors.white }}
                    >
                      <ArrowBackRounded fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box position="absolute" right={2} top="50%" sx={{ transform: "translateY(-50%)" }}>
                    <IconButton
                      onClick={goForward}
                      size="small"
                      disabled={ref.current ? scrollPosition === ref.current.scrollWidth - ref.current.clientWidth : true}
                      aria-label="view next products"
                      sx={{ backgroundColor: colors.white }}
                    >
                      <ArrowForwardRounded fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default ArtistProducts;
