import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authCreateUser, authSignInUser } from "../../../services/Firebase";
import { validate } from "../../../helpers/formValidation";
import { useAppState } from "../../../state";
import { EmailTags } from "../../../types/email";
import AuthContext from "../../../state/auth";
import { addContact } from "../../../services/Klaviyo";

export type UserData = {
  firstName?: string;
  lastName?: string;
  about?: string;
  email?: string;
  paypalEmail?: string;
  password?: string;
  referralCode?: string;
};

const useLoginArtist = (referralCode?: string) => {
  const navigate = useNavigate();
  const { setError } = useAppState();
  const { setUser } = useContext(AuthContext);
  const [signUpUserData, setSignUpUserData] = useState<UserData>({
    email: "",
    paypalEmail: "",
    password: "",
    firstName: "",
    lastName: "",
    ...(referralCode ? { referralCode } : {}),
  });
  const [signInUserData, setSignInUserData] = useState<UserData>({ email: "", password: "" });
  const [signInErrors, setSignInErrors] = useState<UserData>({});
  const [signUpErrors, setSignUpErrors] = useState<UserData & { agreedTerms?: string }>({});
  const [loading, setLoading] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);

  const handleSignInChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setSignInErrors({ ...signInErrors, [name]: "" });
    setSignInUserData({ ...signInUserData, [name]: value });
  };

  const handleSignUpChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setSignUpErrors({ ...signUpErrors, [name]: "" });
    setSignUpUserData({ ...signUpUserData, [name]: value });
  };

  const signInUser = async (event: FormEvent<HTMLFormElement>, redirect = true) => {
    event.preventDefault();
    setLoading(true);
    setSignInErrors({});
    const validation = validate(signInUserData);
    if (validation.error) {
      setSignInErrors(validation.errors);
      setLoading(false);
    } else {
      try {
        await authSignInUser(validation.state, setError, navigate, redirect ? "/" : undefined);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const signUpUser = async (event: FormEvent<HTMLFormElement>, redirect = true) => {
    event.preventDefault();
    setLoading(true);
    setSignUpErrors({});
    const validation = validate(signUpUserData);
    if (validation.error) {
      setSignUpErrors({ ...signUpErrors, ...validation.errors });
      setLoading(false);
      return;
    }
    if (!agreedTerms) {
      setSignUpErrors({ agreedTerms: "Please agree to our terms and conditions to finish registering" });
      setLoading(false);
      return;
    }
    try {
      await authCreateUser(signUpUserData, setError, setUser, navigate, redirect ? "/profile" : undefined);
      const { email, firstName, lastName } = signUpUserData;
      if (email) {
        await addContact({ email, firstName, lastName, tags: [EmailTags.Artist] });
      }
      setSignUpUserData({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error signing up user:", error);
    }
  };

  const onTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreedTerms(event.target.checked);
  };

  return {
    handleSignInChange,
    handleSignUpChange,
    signInUser,
    signUpUser,
    onTermsChange,
    agreedTerms,
    signInErrors,
    signUpErrors,
    loading,
  };
};

export default useLoginArtist;
